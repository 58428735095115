export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const RESET_WIZARD = "RESET_WIZARD";
export const SAVE_WIZARD_DATA = "SAVE_WIZARD_DATA";

export function nextStep(){
    return {
        type: NEXT_STEP
    }
}

export function previousStep(){
    return {
        type: PREVIOUS_STEP
    }
}

export function resetWizard(){
    return {
        type: RESET_WIZARD
    }
}

export function saveWizardData(wizardItem){
    return {
        type: SAVE_WIZARD_DATA,
        payload: {
            name: wizardItem.name,
            value: wizardItem.value
        }
    }
}