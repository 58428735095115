import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { BrowserRouter } from "react-router-dom";

import { saveState } from './utils/storageManager';

import { Provider } from 'react-redux';
import { createStore } from 'redux';

import { stipendijeReducers } from './reducers/index';
const store = createStore(stipendijeReducers);

function saveStateToCookie(){
  saveState(store.getState());
}

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <BrowserRouter>
    <Provider store={ store }>
      <App saveStateToCookie={saveStateToCookie} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();