import { SAVE_IZRACUN_PROPERTY, ADD_NATJECANJE, DELETE_NATJECANJE, ADD_PROJEKT, DELETE_PROJEKT, ADD_NAGRADA, DELETE_NAGRADA, CLEAR_INPUT_MODALS } from '../actions/dActions';

import loadState from '../utils/storageManager';

const initialState = {
    ukupniPrihod: "",
    brojClanova: "",
    samohraniRoditelj: false,
    roditelj: false,
    checkedProjekti: false,
    openProjekti: false,
    currentProjectTip: "",
    currentProjectGodina: "",
    currentProjectDonator: "",
    currentProjectNaziv: "",
    checkedNagrade: false,
    openNagrade: false,
    currentNagradaTip: "",
    currentNagradaGodina: "",
    currentNagradaNaziv: "",
    checkedNatjecanja: false,
    openNatjecanja: false,
    currentNatjecanjeMjesto: "",
    currentNatjecanjeTip: "",
    currentNatjecanjeGodina: "",
    currentNatjecanjePredmet: "",
    natjecanja: [], // [1. mjesto] Državno natjecanje - FIZIKA (2017.)
    nagrade: [], // [Rektorova nagrada - 2018.] Proteomski pristup pretilosti upasa
    projekti: [], // [Voditelj projektnog tima - 2017.] - Youth lead (Agencija za mobilnost i programe EU)
    sumaBodova: 0,
}

const testState = {
    ukupniPrihod: "900",
    brojClanova: "2",
    samohraniRoditelj: false,
    roditelj: false,
    checkedProjekti: true,
    openProjekti: false,
    currentProjectTip: "",
    currentProjectGodina: "",
    currentProjectDonator: "",
    currentProjectNaziv: "",
    checkedNagrade: true,
    openNagrade: false,
    currentNagradaTip: "",
    currentNagradaGodina: "",
    currentNagradaNaziv: "",
    checkedNatjecanja: true,
    openNatjecanja: false,
    currentNatjecanjeMjesto: "",
    currentNatjecanjeTip: "",
    currentNatjecanjeGodina: "",
    currentNatjecanjePredmet: "",
    natjecanja: [{
        key: 0,
        tip: "Državno natjecanje",
        godina: 2019,
        mjesto: "Zagreb",
        predmet: "Matematika",
    }], // [1. mjesto] Državno natjecanje - FIZIKA (2017.)
    nagrade: [{
        key: 0,
        tip: "Dekanova nagrada",
        godina: 2019,
        naslov: "Some shit"
    }], // [Rektorova nagrada - 2018.] Proteomski pristup pretilosti upasa
    projekti: [{
        key: 0,
        tip: "Voditelj projektnog tima",
        godina: 2019,
        naziv: "Okoloko",
        donator: "Ministarstvo regionalnog razvoja",
    }], // [Voditelj projektnog tima - 2017.] - Youth lead (Agencija za mobilnost i programe EU)
    sumaBodova: 0,
}

export default function dReducers(state = initialState, action){
    switch(action.type){
        case SAVE_IZRACUN_PROPERTY:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case ADD_NATJECANJE:
            return {
                ...state,
                natjecanja: [...state.natjecanja, action.payload]
            }
        case DELETE_NATJECANJE:
            let natjecanja = [...state.natjecanja];
            let natjecanjeToDelete = natjecanja.indexOf(action.payload);
            natjecanja.splice(natjecanjeToDelete, 1);
            return {
                ...state,
                natjecanja: natjecanja
            }
        case ADD_NAGRADA:
            return {
                ...state,
                nagrade: [...state.nagrade, action.payload]
            }
        case DELETE_NAGRADA:
            const nagrade = [...state.nagrade];
            const nagradaToDelete = nagrade.indexOf(action.payload);
            nagrade.splice(nagradaToDelete, 1);
            return {
                ...state,
                nagrade: nagrade
            }
        case ADD_PROJEKT:
            return {
                ...state,
                projekti: [...state.projekti, action.payload]
            }
        case DELETE_PROJEKT:
            const projekti = [...state.projekti];
            const projektToDelete = projekti.indexOf(action.payload);
            projekti.splice(projektToDelete, 1);
            return {
                ...state,
                projekti: projekti
            }
        case CLEAR_INPUT_MODALS:
            return {
                ...state,
                openNagrade: false,
                currentNagradaTip: "",
                currentNagradaGodina: "",
                currentNagradaNaziv: "",
                openNatjecanja: false,
                currentNatjecanjeTip: "",
                currentNatjecanjeGodina: "",
                currentNatjecanjeMjesto: "",
                currentNatjecanjePredmet: "",
                openProjekti: false,
                currentProjectTip: "",
                currentProjectGodina: "",
                currentProjectDonator: "",
                currentProjectNaziv: "",
            }
        default:
            let storedState = loadState();
            return storedState.hasOwnProperty("d") ? storedState.d : state;
    }
}