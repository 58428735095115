import { combineReducers } from 'redux';

import wizardReducer from './wizardReducer';
import seReducer from './seReducer';
import dReducer from './dReducer';
import iReducer from './iReducer';

export const stipendijeReducers = combineReducers(
    {
        wizard: wizardReducer,
        se: seReducer,
        d: dReducer,
        i: iReducer
    }
);