import { SAVE_IZRACUN_PROPERTY } from '../actions/seActions';

import loadState from '../utils/storageManager';

const initialState = {
    ukupniPrihod: "",
    brojClanova: "",
    samohraniRoditelj: false,
    roditelj: false,
    sumaBodova: 0
}

const testState = {
    ukupniPrihod: "900",
    brojClanova: "2",
    samohraniRoditelj: false,
    roditelj: false,
    sumaBodova: 0
}

export default function seReducers(state = initialState, action){
    switch(action.type){
        case SAVE_IZRACUN_PROPERTY:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        default:
            let storedState = loadState();
            return storedState.hasOwnProperty("se") ? storedState.se : state;
    }
}