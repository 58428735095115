function getData() {
    let name = "stipendijeData=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

export default function loadState(){
    let state = {};

    let stringData = getData();
    if (stringData !== "") {
        state = JSON.parse(stringData);
    }
    return state;
}

export function saveState(state){
    let currentDate = new Date();
    let duration = 7 * 24 * 60 * 60 * 1000; // days hours minutes seconds miliseconds
    let expireDate = new Date();
    expireDate.setTime(currentDate.getTime() + duration);

    let stateAsString = JSON.stringify(state);
    document.cookie = `stipendijeData=${stateAsString};expires=${expireDate.toUTCString()};path=/`;
}
