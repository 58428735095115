import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MathJax from 'react-mathjax';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Tooltip, IconButton, Zoom } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const chartOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: "Struktura bodova za dodjelu stipendija po kriteriju izvrsnosti"
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.name}: {point.y}',
            },
            showInLegend: true
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'Uspjeh',
            y: 60,
            sliced: true,
            selected: true
        }, {
            name: 'Projektne aktivnosti',
            y: 10
        }, {
            name: 'Nagrade',
            y: 10
        }, {
            name: 'Razmjene',
            y: 10
        }, {
            name: 'Objavljeni radovi',
            y: 10
        }]
    }]
};

export default function KriterijIzvrsni() {
    return (
        <div>
            <CssBaseline />
            <Container maxWidth="lg">
                <Typography variant="h4" className="mb-5">Kriteriji za dodjelu stipendija izvrsnim studentima</Typography>

                <Typography variant="h5" paragraph={true}>Tko se može prijaviti?</Typography>

                <Typography variant="h6" paragraph={true}>Studenti koji</Typography>
                <ul>
                    <li>Su podnijeli potpunu i pravodobnu prijavu sa pripadajućomdokumentacijom i na propisanim
                        obrascima
                    </li>
                    <li>Su u prethodnoj akademskoj godini stekli minimalno 60 ECTS bodova, odnosno  da  su  u  prethodnoj  redovnoj  akademskoj  godini položili sve ispite</li>
                    <li>Imaju prebivalište u Lepoglavi i redovni su studenti na upisanom učilištu</li>
                    <li>Imaju prosjek veći od 4,00</li>
                    <li>su redovni studenti</li>
                    <ul>
                        <li>druge ili više godine na preddiplomskom studiju</li>
                        <li>druge ili više godine na integriranom preddiplomskom i diplomskom studiju</li>
                        <li>bilo koje godine diplomskog studija</li>
                    </ul>
                </ul>
                * Prednost pri jednakom broju bodova imaju studenti s većim prosjekom

                <Typography variant="h5" paragraph={true} style={{ textTransform: "uppercase" }} className="mt-5 mb-3">Bodovi</Typography>

                <HighchartsReact highcharts={Highcharts} options={chartOptions} />

                <Typography variant="h6" paragraph={true} className="mt-3">Uspjeh</Typography>
                <ul>
                    <li>Maksimalno je moguće sakupiti 60 bodova</li>
                    <li>Bodovi se računaju po formuli
                            <MathJax.Provider>
                            <MathJax.Node formula={'bodovi =  60 \\cdot (prosjek - 4) '} />
                        </MathJax.Provider>
                    </li>
                </ul>

                <Typography variant="h6" paragraph={true}>Projektne aktivnosti</Typography>
                <ul>
                    <li>Maksimalno je moguće s kupiti 10 bodova, boduje se sudjelovanje u projektu u organizaciji fakulteta, udruga ili drugih inicijativa mladih</li>
                    <ul>
                        <li>Član projektnog tima (1 bod)</li>
                        <li>Vođa projektnog tima (2 boda)
                        <Tooltip TransitionComponent={Zoom} title="podrazumijeva ulaganje više vremena, koordinacije i organizacije">
                                    <IconButton>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                        </li>
                    </ul>
                </ul>

                <Typography variant="h6" paragraph={true}>Nagrade</Typography>
                <ul>
                    <li>Moguće je sakupiti ukupno 10 bodova</li>
                    <ul>
                        <li>Rektorova nagrada (6 bodova)</li>
                        <li>Dekanova nagrada (4 boda)</li>
                    </ul>

                </ul>

                <Typography variant="h6" paragraph={true}>Razmjene</Typography>
                <ul>
                    <li>Maksimalno je moguće sakupiti 10 bodova, boduje se sudjelovanje na razmjeni prema semestrima provedenim na razmjeni ili kraće razmjene.</li>
                    <ul>
                        <li>Razmjena u trajanju jednog semestra (10 bodova)</li>
                        <li>Razmjena u trajanju minimalno 2 mjeseca (5 bodova)</li>
                        <li>Razmjene u trajanju manje od 2 mjeseca (2 boda)</li>
                    </ul>
                </ul>

                <Typography variant="h6" paragraph={true}>Objavljeni radovi</Typography>
                <ul>
                    <li>Maksimalno je moguće sakupiti 10 bodova, sa 10 bodova boduju se samo radovi objavljeni u literaturi koju prepoznaje sveučilište ili literaturi koju priznaje posebnom odlukom tijelo županije ili tijelo jedinice lokalne samouprave.</li>
                </ul>

                <Typography variant="h6" paragraph={true}>Dodatni bodovi</Typography>
                <ul>
                    <li>Bodovi se zbrajaju ostalim bodovima ostvarenim u gore navedenim kriterijima</li>
                    <li>STUDENTI SA INVALIDITETOM</li>
                    <ul>
                        <li>Studenti sa utvrđenim invaliditetom 0 – 50 % (10 bodova)</li>
                        <li>Studenti sa utvrđenim invaliditetom 51 – 100 % (15 bodova)</li>
                    </ul>
                    <li>VOLONTERSTVO U INTERESU GRADA LEPOGLAVE</li>
                    <ul>
                        <li>Do 19 sati volontiranja (2 boda)</li>
                        <li>20 - 29 sati volontiranja (4 boda)</li>
                        <li>30 - 39 sati volontiranja (6 boda)</li>
                        <li>40 - 49 sati volontiranja (8 boda)</li>
                        <li>50 i više sati volontiranja (10 boda)</li>
                    </ul>
                </ul>

            </Container>

        </div>
    );
}