import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import "../../bootstrap.min.css";

import constData from '../../data/constants.json';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Typography, Container } from '@material-ui/core';

class Usporedi extends Component {
    getChartOptions() {
        var options = {
            chart: {
                type: 'column',
                height: '50%'
            },
            title: {
                text: 'Usporedba po kriterijima'
            },
            yAxis: [{
                min: 0,
                title: {
                    text: 'Bodovi'
                }
            }, {
                title: {
                    text: ''
                },
                opposite: "true"
            }],
            legend: {
                shadow: "false"
            },
            tooltip: {
                shared: "true"
            },
            plotOptions: {
                column: {
                    grouping: "false",
                    shadow: "false",
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Socio-ekonomski',
                //"color": Const.COLOR_COMPARE_1,
                data: [this.isPrijavaValidSE() ? this.props.se.sumaBodova : 0, 0, 0],
                pointPadding: 0.0,
                pointPlacement: 0.0
            }, {
                name: 'Deficitarni',
                //"color": Const.COLOR_COMPARE_2,
                data: [0, this.isPrijavaValidD() ? this.props.d.sumaBodova : 0, 0],
                pointPadding: 0.0,
                pointPlacement: 0.0
            },
            {
                name: 'Izvrsnost',
                //"color": Const.COLOR_COMPARE_3,
                data: [0, 0, this.isPrijavaValidI() ? this.props.i.sumaBodova : 0],
                pointPadding: 0.0,
                pointPlacement: 0.0
            }
            ]
        };
        return options;
    }

    isMatura() {
        return this.props.wizard.godina === "1" && (
            this.props.wizard.studij === constData.studijiOptions[1].value ||
            this.props.wizard.studij === constData.studijiOptions[3].value ||
            this.props.wizard.studij === constData.studijiOptions[4].value);
    }

    isPrijavaValidSE() {
        var validEcts = true;
        if (!this.isMatura()) {
            validEcts = this.props.wizard.ects >= 50;
        }

        return this.props.wizard.prosjek > 3 &&
            this.props.wizard.prosjek <= 5 &&
            validEcts;
    }

    isPrijavaValidI() {
        return this.props.wizard.prosjek > 4 &&
            this.props.wizard.prosjek <= 5 &&
            this.props.wizard.ects >= 55 &&
            !this.isMatura();
    }

    isPrijavaValidD() {
        var validEcts = true;
        if (!this.isMatura()) {
            validEcts = this.props.wizard.ects >= 50;
        }

        return this.props.wizard.prosjek > 3 &&
            this.props.wizard.prosjek <= 5 &&
            validEcts;
    }

    renderRedirect() {
        if (!this.props.wizard.isWizardCompleted) {
            return (<Redirect to='/carobnjak' />);
        }
    }

    render() {
        return (
            <Container maxWidth="xl" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                {this.renderRedirect()}
                {!(this.isPrijavaValidSE() && this.isPrijavaValidD() && this.isPrijavaValidI()) ? (
                    <div className="alert alert-danger custom-alert-style" role="alert">
                        Nažalost, ne zadovoljavaš prijavu za stipendiju po niti jednom kriteriju.
                    </div>
                ) : (this.props.se.sumaBodova === 0 && this.props.d.sumaBodova === 0 && this.props.i.sumaBodova === 0) ?
                        <Typography variant="h5">Za usporedbu moraš spremiti podatke izračuna.</Typography> : <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} />
                }
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        wizard: state.wizard,
        se: state.se,
        d: state.d,
        i: state.i
    }
}

export default connect(mapStateToProps, null)(Usporedi);