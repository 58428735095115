import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MathJax from 'react-mathjax';

import { Tooltip, IconButton, Container, Typography, CssBaseline, Zoom } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const chartOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Struktura bodova za dodjelu stipendija po kriteriju slabijeg socio-ekonomskog statusa'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.name}: {point.y}',
            },
            showInLegend: true
        }
    },
    series: [{
        name: 'Postotak',
        colorByPoint: true,
        data: [{
            name: 'Prihod po članu zajedničkog kućanstva',
            y: 60
        }, {
            name: 'Student koji živi samo s jednim roditeljem',
            y: 10
        }, {
            name: 'Student koji je roditelj',
            y: 10
        }, {
            name: 'Uspjeh',
            y: 20,
            sliced: true,
            selected: true
        }]
    }]
};

export default function KriterijSocioEkonomski() {
    return (
        <div>
            <CssBaseline />
            <Container maxWidth="lg">
                <Typography variant="h4" className="mb-5">
                    Kriteriji za dodjelu stipendija studentima slabijeg socio-ekonomskog statusa
                </Typography>
                <Typography variant="h5" paragraph={true} style={{ textTransform: "uppercase" }}>Tko se može prijaviti?</Typography>

                <Typography variant="h6" paragraph={true}>Studenti koji</Typography>
                <ul>
                    <li>Su podnijeli potpunu i pravodobnu prijavu sa pripadajućomdokumentacijom i na propisanim
                        obrascima
                        </li>
                    <li>Su u prethodnoj akademskoj godini stekli minimalno 50 ECTS bodova
                            <span>
                            <Tooltip TransitionComponent={Zoom} title="Ne odnosi se na studente prve akademske godine preddiplomskogstudija i integriranog preddiplomskog i diplomskog studija">
                                <IconButton>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </li>
                    <li>Imaju prebivalište u Lepoglavi i redovni su studenti na upisanom učilištu</li>
                    <li>Imaju prosjek veći od 3,00</li>
                </ul>
                <b>Prednost pri jednakom broju bodova imaju studenti s manjim prihodom po članu zajedničkog kućanstva</b>

                <Typography variant="h5" paragraph={true} className="mt-5 mb-3" style={{ textTransform: "uppercase" }}>Bodovi</Typography>

                <HighchartsReact highcharts={Highcharts} options={chartOptions} />

                <Typography variant="h6" paragraph={true} className="mt-3">Prihod po članu zajedničkog kućanstva</Typography>
                <ul>
                    <li>dio kućanstva su roditelji, braća, sestre te bake i djedovi koji žive sa studentom</li>
                    <ul>
                        <li>prihod manji od 750,00 kn po članu nosi maksimalni broj bodova (60 bodova)</li>
                        <li>prihod veći od 3.500,00 kn po članu nosi minimalni broj bodova (0 bodova)</li>
                        <li>bodovi za prihod između 750,00 i 3.500,00 kn računaju se po formuli
                            <MathJax.Provider>
                                <MathJax.Node formula={'bodovi =  \\frac{(3500 - prihod)\\cdot 60}{2750} '} />
                            </MathJax.Provider>
                        </li>
                    </ul>
                </ul>

                <Typography variant="h6" paragraph={true}>Uspjeh</Typography>
                <ul>
                    <li>Maksimalno je moguće sakupiti 20 bodova za studente viših godina i 14 (18.2) bodova za studente prve godine preddiplomskih i integriranih preddiplomskih i diplomskih studija</li>
                    <li>Prosjek manji i jednak 3,00 nosi 0 bodova</li>
                    <li>Bodovi se računaju po formuli</li>
                    <ul>
                        <li>studenti viših godina:
                            <MathJax.Provider>
                                <MathJax.Node formula={'bodovi =  \\frac{(prosjek \\cdot ECTS)}{6} -30 '} />
                            </MathJax.Provider>
                        </li>
                        <li>studenti prve godine:
                            <MathJax.Provider>
                                <MathJax.Node formula={'bodovi = 7 \\cdot (prosjek - 3) \\cdot razina '} />
                            </MathJax.Provider>
                            pri čemu je razina = 1.3 za položenu višu razinu mature (A), odnosno razina = 1 za položenu nižu razinu mature (B)</li>
                    </ul>
                </ul>

                <Typography variant="h6" paragraph={true}>Student koji živi samo s jednim roditeljem</Typography>
                <ul>
                    <li>studenti kojima je jedan roditelj umro, nestao ili nepoznat te studenti razvedenih roditelja
                        (10 bodova)
                    </li>
                </ul>

                <Typography variant="h6" paragraph={true}>Student koji je roditelj</Typography>
                <ul>
                    <li>student koji je roditelj (10 bodova)
                    <Tooltip TransitionComponent={Zoom} title="bodovi su neovisni o broju djece">
                            <IconButton>
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </li>
                </ul>

                <Typography variant="h6" paragraph={true}>Dodatni bodovi</Typography>
                <ul>
                    <li>Bodovi se zbrajaju ostalim bodovima ostvarenim u gore navedenim kriterijima</li>
                    <li>STUDENTI SA INVALIDITETOM</li>
                    <ul>
                        <li>Studenti sa utvrđenim invaliditetom 0 – 50 % (10 bodova)</li>
                        <li>Studenti sa utvrđenim invaliditetom 51 – 100 % (15 bodova)</li>
                    </ul>
                    <li>VOLONTERSTVO U INTERESU GRADA LEPOGLAVE</li>
                    <ul>
                        <li>Do 19 sati volontiranja (2 boda)</li>
                        <li>20 - 29 sati volontiranja (4 boda)</li>
                        <li>30 - 39 sati volontiranja (6 boda)</li>
                        <li>40 - 49 sati volontiranja (8 boda)</li>
                        <li>50 i više sati volontiranja (10 boda)</li>
                    </ul>
                </ul>

            </Container>
        </div>
    );
}