import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export default function Home() {
    return (
        <div>
            <CssBaseline />
            <Container maxWidth="lg">
                <Typography variant="h4" className="mb-5">
                    Opće informacije
                </Typography>

                <Typography className="mb-5">
                    <div className="alert alert-success" role="alert">
                        INFO: Aplikaciju za izračun izradila je <b>Nezavisna udruga mladih</b>, a natječaj za stipendije raspisuje <b>Grad Lepoglava</b>.
                    </div>
                </Typography>
                
                
                <Typography paragraph={true} align="justify">
                    Aplikacija je izrađena u suradnji sa <strong>Savjetom mladih Grada Lepoglave</strong> i <strong>Gradom Lepoglavom</strong> te služi
                    isključivo u informativne svrhe. Broj bodova potvrđuje <strong>Povjerenstvo za dodjelu stipendija studentima Grada Lepoglave</strong> na 
                    temelju dostavljene dokumentacije. Također je potrebno provjeriti sve objavljene dokumente na službenoj
                    <a href="http://lepoglava.hr" target="_blank" rel="noopener noreferrer"> web stranici Grada Lepoglave</a> koji su potrebni za propisnu prijavu na objavljeni natječaj.
                </Typography>

                <Typography paragraph={true} align="justify">
                    Opći uvjeti, postupak i kriteriji dodjele stipendija, te način ostvarivanja prava na dodjelu stipendija
                    redovnim studentima s prebivalištem na području Grada Lepoglave propisani su <b>ODLUKOM o uvjetima i načinu ostvarivanja prava na dodjelu stipendija studentima Grada Lepoglave </b>
                    <a href="https://glasila.hr/upload_data/site_files/svvz5014.pdf" target="_blank" rel="noopener noreferrer">  [SVŽŽ broj 50, godina XXII]</a>, dok je
                    dopuna odluci propisana <b>ODLUKOM o izmjenama i dopunama Odluke o uvjetima i načinu ostvarivanja prava na dodjelu stipendija studentima Grada Lepoglave</b>
                    <a href="https://glasila.hr/upload_data/site_files/svvz7817.pdf" target="_blank" rel="noopener noreferrer">  [SVŽŽ broj 78, godina XXV]</a>.
                </Typography>
            </Container>
        </div>
    );
}