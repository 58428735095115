import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import constData from '../../data/constants.json';

import { Grid, Container, Button, TextField, Switch, Typography } from '@material-ui/core';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import SaveIcon from '@material-ui/icons/Save';

import { SAVE_IZRACUN_PROPERTY } from '../../actions/seActions';

class IzracunSocioEkonomski extends Component {

    renderRedirect() {
        if (!this.props.wizard.isWizardCompleted) {
            return (<Redirect to='/carobnjak' />);
        }
    }

    saveChanges = e => {
        e.preventDefault();
        if (!this.isValidBrojClanova() && !this.isValidUkupniPrihod()) {
            return;
        }
        this.props.saveIzracunProperty("sumaBodova", this.izracunajSumuBodova());
        this.props.saveStateToCookie();
    }

    updateStateWithEvent = (event) => {
        //this.setState({ [event.target.name]: event.target.value });
        this.props.saveIzracunProperty(event.target.name, event.target.value);
    }

    updateStateMaterialChecked = name => event => {
        //this.setState({ [name]: event.target.checked });
        this.props.saveIzracunProperty(name, event.target.checked);
    }

    isValidUkupniPrihod() {
        return this.props.se.ukupniPrihod !== "" &&
            this.props.se.ukupniPrihod >= 0
    }
    isValidBrojClanova() {
        return this.props.se.brojClanova !== "" &&
            this.props.se.brojClanova >= 1
    }

    isMatura() {
        return this.props.wizard.godina === "1" && (
            this.props.wizard.studij === constData.studijiOptions[1].value ||
            this.props.wizard.studij === constData.studijiOptions[3].value ||
            this.props.wizard.studij === constData.studijiOptions[4].value);
    }

    izracunajBodove() {
        // PRIHOD
        var bodoviPrihoda = 0;
        var prihodPoClanu = this.props.se.ukupniPrihod / this.props.se.brojClanova;
        if (prihodPoClanu < 750) {
            bodoviPrihoda = 60;
        }
        else if (prihodPoClanu > 3500) {
            bodoviPrihoda = 0;
        }
        else {
            bodoviPrihoda = (3500 - prihodPoClanu) * 60 / 2750;
        }

        // USPJEH
        var bodoviUspjeha = 0;
        // matura
        if (this.isMatura()) {
            var razina = constData.maturaOptions.find(o => o.value === this.props.wizard.matura).razina;
            bodoviUspjeha = 7 * (this.props.wizard.prosjek - 3) * razina;
        }
        else { // faks
            bodoviUspjeha = (this.props.wizard.prosjek * this.props.wizard.ects) / 6 - 30;
        }

        var bodovi = {
            prihod: bodoviPrihoda,
            samohraniRoditelj: this.props.se.samohraniRoditelj ? 10 : 0,
            roditelj: this.props.se.roditelj ? 10 : 0,
            uspjeh: bodoviUspjeha,
            invaliditet: this.props.wizard.checkedInvaliditet ? constData.invaliditetOptions.find(o => o.value === this.props.wizard.invaliditet).bodovi : 0,
            volontiranje: this.props.wizard.checkedVolontiranje ? constData.volontiranjeOptions.find(o => o.value === this.props.wizard.volontiranje).bodovi : 0
        }
        return bodovi;
    }
    izracunajSumuBodova() {
        var bodovi = this.izracunajBodove();
        var suma = 0;
        for (var key in bodovi) {
            suma += bodovi[key];
        }
        return suma;
    }

    getChartOptions() {
        var bodovi = this.izracunajBodove();

        var ostvareniBodovi = [bodovi.prihod, bodovi.uspjeh];
        var moguciBodovi = [60, 20];
        var kategorije = ['Bodovi prema prihodu', 'Bodovi prema uspjehu'];

        if (this.props.se.samohraniRoditelj) {
            kategorije.push("Student živi samo s jednim roditeljem");
            ostvareniBodovi.push(bodovi.samohraniRoditelj);
            moguciBodovi.push(10);
        }
        if (this.props.se.roditelj) {
            kategorije.push("Student je roditelj");
            ostvareniBodovi.push(bodovi.roditelj);
            moguciBodovi.push(10);
        }

        if (this.props.wizard.checkedInvaliditet) {
            kategorije.push("Invaliditet");
            ostvareniBodovi.push(bodovi.invaliditet);
            moguciBodovi.push(15);
        }
        if (this.props.wizard.checkedVolontiranje) {
            kategorije.push("Volontiranje");
            ostvareniBodovi.push(bodovi.volontiranje);
            moguciBodovi.push(10);
        }

        var options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: ""
            },
            xAxis: {
                categories: kategorije,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Broj bodova',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 200,
                floating: true,
                borderWidth: 1,
                backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Ostvareno',
                data: ostvareniBodovi
            }, {
                name: 'Moguće',
                data: moguciBodovi
            }]
        }
        return options;
    }

    showIzracunContent() {
        return (
            <div>
                <Container maxWidth="xl" className="my-4 py-3" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item xs={12} lg={2}>
                            <TextField
                                id="outlined-number"
                                label="Ukupni prihod"
                                fullWidth={true}
                                value={this.props.se.ukupniPrihod}
                                error={!this.isValidUkupniPrihod()}
                                onChange={this.updateStateWithEvent.bind(this)}
                                name="ukupniPrihod"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: "0", step: "50" }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <TextField
                                id="outlined-number"
                                label="Broj članova kućanstva"
                                fullWidth={true}
                                value={this.props.se.brojClanova}
                                error={!this.isValidBrojClanova()}
                                onChange={this.updateStateWithEvent.bind(this)}
                                name="brojClanova"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: "1" }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Switch
                                checked={this.props.se.samohraniRoditelj}
                                onChange={this.updateStateMaterialChecked('samohraniRoditelj')}
                                color="primary"
                                value="samohraniRoditelj"
                                label=""
                                size="medium"
                            />
                            <label>Samohrani roditelj</label>
                        </Grid>

                        <Grid item xs={12} lg={2}>
                            <Switch
                                checked={this.props.se.roditelj}
                                onChange={this.updateStateMaterialChecked('roditelj')}
                                color="primary"
                                value="roditelj"
                                label=""
                                size="medium"
                            />
                            <label>Student je roditelj</label>
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            <Button variant="contained" color="primary" onClick={this.saveChanges} fullWidth={true}><SaveIcon /> Spremi</Button>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="xl" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    <Typography className="mb-1 py-3"><b>UKUPAN BROJ BODOVA: {this.izracunajSumuBodova()}</b></Typography>
                    {
                        this.isValidUkupniPrihod() && this.isValidBrojClanova() ? (
                            <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} />
                        ) : undefined
                    }
                </Container>
            </div>
        );
    }

    isPrijavaValid() {
        var validEcts = true;
        if (!this.isMatura()) {
            validEcts = this.props.wizard.ects >= 50;
        }

        return this.props.wizard.prosjek > 3 &&
            this.props.wizard.prosjek <= 5 &&
            validEcts;
    }

    render() {
        return (
            <Container maxWidth="xl">
                {this.renderRedirect()}

                <Typography variant="h4" paragraph={true}>
                    [IZRAČUN BODOVA] Kriteriji za dodjelu stipendija studentima slabijeg socio-ekonomskog statusa
                </Typography>

                <b>* Dio podataka si unio kroz čarobnjak. Polja označena sa * su obavezna kako bi sustav izvršio potrebne izračune. Ukoliko ne postoje dodatne informacije koje želiš unijeti u ovaj obrazac, klikni izračunaj za prikaz bodova.</b>

                {this.props.wizard.prosjek <= 3 ? (
                    <div className="alert alert-danger custom-alert-style" role="alert">
                        Za prijavu na ovaj kriterij moraš imati prosjek ocjena iznad 3.
                    </div>
                ) : undefined}

                {!this.isMatura() && this.props.wizard.ects < 50 ? (
                    <div className="alert alert-danger custom-alert-style" role="alert">
                        Za prijavu na ovaj kriterij moraš imati minimalno 50 ECTS bodova.
                    </div>
                ) : undefined}

                {this.isPrijavaValid() ? (
                    this.showIzracunContent()
                ) : undefined}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        wizard: state.wizard,
        se: state.se
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveIzracunProperty: (name, value) => dispatch({ type: SAVE_IZRACUN_PROPERTY, payload: { name: name, value: value } })
})

export default connect(mapStateToProps, mapDispatchToProps)(IzracunSocioEkonomski);