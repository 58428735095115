import { NEXT_STEP, PREVIOUS_STEP, RESET_WIZARD, SAVE_WIZARD_DATA } from '../actions/wizardActions';

import constData from '../data/constants';
import loadState from '../utils/storageManager';

const initialState = {
    changesAreSaved: false,
    stepsWithError: [false, false, false, false, false],
    activeStep: 0,
    steps: ['Trenutni studij', 'Prethodno obrazovanje', 'Ostalo'],
    isWizardCompleted: false,
    studij: constData.studijiOptions[0].value,
    godina: constData.godinaOptions[0].value,
    matura: constData.maturaOptions[0].value,
    ects: "",
    prosjek: "",
    checkedInvaliditet: false,
    invaliditet: constData.invaliditetOptions[0].value,
    checkedVolontiranje: false,
    volontiranje: constData.volontiranjeOptions[0].value,
}

const testStateViseGodine = {
    changesAreSaved: true,
    stepsWithError: [false, false, false, false, false],
    activeStep: 3,
    steps: ['Trenutni studij', 'Prethodno obrazovanje', 'Ostalo'],
    isWizardCompleted: true,
    studij: constData.studijiOptions[1].value,
    godina: constData.godinaOptions[2].value,
    matura: constData.maturaOptions[0].value,
    ects: "60",
    prosjek: "5",
    checkedInvaliditet: false,
    invaliditet: constData.invaliditetOptions[0].value,
    checkedVolontiranje: false,
    volontiranje: constData.volontiranjeOptions[0].value,
}

const testStatePrvaGodina = {
    changesAreSaved: true,
    stepsWithError: [false, false, false, false, false],
    activeStep: 3,
    steps: ['Trenutni studij', 'Prethodno obrazovanje', 'Ostalo'],
    isWizardCompleted: true,
    studij: constData.studijiOptions[1].value,
    godina: constData.godinaOptions[1].value,
    matura: constData.maturaOptions[2].value,
    ects: "",
    prosjek: "5",
    checkedInvaliditet: false,
    invaliditet: constData.invaliditetOptions[0].value,
    checkedVolontiranje: false,
    volontiranje: constData.volontiranjeOptions[0].value,
}

export default function wizardReducer(state = initialState, action){
    switch(action.type){
        case NEXT_STEP:
            let nextStep = state.activeStep + 1;

            if (nextStep < state.steps.length){
                return {
                    ...state,
                    activeStep: nextStep
                }
            }
            else{
                return {
                    ...state,
                    activeStep: nextStep,
                    isWizardCompleted: true

                }
            }
        case PREVIOUS_STEP:
            let currentStep = state.activeStep;
            if (currentStep -1 >= 0) {
                currentStep--;
            }
            return {
                ...state,
                activeStep: currentStep
            }
        case SAVE_WIZARD_DATA:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case RESET_WIZARD:
            return initialState;
        default:
            let storedState = loadState();
            return storedState.hasOwnProperty("wizard") ? storedState.wizard : state;
    }
}