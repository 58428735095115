import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import constData from '../../data/constants.json';

import { InputLabel, Select, FormHelperText, FormControl, Fab, Container, Typography, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { SAVE_IZRACUN_PROPERTY, ADD_PROJEKT, DELETE_PROJEKT, ADD_NAGRADA, DELETE_NAGRADA, ADD_RAZMJENA, DELETE_RAZMJENA, CLEAR_INPUT_MODALS } from '../../actions/iActions';

class IzracunIzvrsni extends Component {

    renderRedirect() {
        if (!this.props.wizard.isWizardCompleted) {
            return (<Redirect to='/carobnjak' />);
        }
    }

    saveChanges = e => {
        e.preventDefault();
        this.props.saveIzracunProperty("sumaBodova", this.izracunajSumuBodova());
        this.props.saveStateToCookie();
    }

    updateStateWithEvent = (event) => {
        //this.setState({ [event.target.name]: event.target.value });
        this.props.saveIzracunProperty(event.target.name, event.target.value);
    }

    updateStateMaterialChecked = name => event => {
        //this.setState({ [name]: event.target.checked });
        this.props.saveIzracunProperty(name, event.target.checked);
    }

    isMatura() {
        return this.props.wizard.godina === "1" && (
            this.props.wizard.studij === constData.studijiOptions[1].value ||
            this.props.wizard.studij === constData.studijiOptions[3].value ||
            this.props.wizard.studij === constData.studijiOptions[4].value);
    }

    getLastYearsOptions() {
        var currentYear = new Date().getFullYear();
        var years = [{
            value: "",
            text: ""
        }];
        for (var i = currentYear; i >= currentYear - 6; i--) {
            years.push({
                value: i,
                text: i
            });
        }
        return years;
    }

    izracunajBodove() {
        // USPJEH
        var bodoviUspjeha = 0;
        if (!this.isMatura()) {
            bodoviUspjeha = 60 * (this.props.wizard.prosjek - 4);
        }
        if (bodoviUspjeha > 60) {
            bodoviUspjeha = 60;
        }

        // PROJEKTI
        var bodoviProjekti = 0;
        for (let index in this.props.i.projekti) {
            let bodovi = constData.projektiOptions.find(o => o.text === this.props.i.projekti[index].tip).bodovi;
            if (bodovi !== undefined && bodovi > 0) {
                bodoviProjekti += bodovi;
            }
        }
        if (bodoviProjekti > 10) {
            bodoviProjekti = 10;
        }

        // NAGRADE
        var bodoviNagrade = 0;
        if (!this.isMatura()) {
            for (let index in this.props.i.nagrade) {
                let bodovi = constData.nagradeOptions.find(o => o.text === this.props.i.nagrade[index].tip).bodovi;
                if (bodovi !== undefined && bodovi > 0) {
                    bodoviNagrade += bodovi;
                }
            }
        }
        if (bodoviNagrade > 10) {
            bodoviNagrade = 10;
        }

        // RAZMJENE
        var bodoviRazmjene = 0;
        if (!this.isMatura()) {
            for (let index in this.props.i.razmjene) {
                let bodovi = constData.razmjeneOptions.find(o => o.text === this.props.i.razmjene[index].tip).bodovi;
                if (bodovi !== undefined && bodovi > 0) {
                    bodoviRazmjene += bodovi;
                }
            }
        }
        if (bodoviRazmjene > 10) {
            bodoviRazmjene = 10;
        }

        var bodovi = {
            uspjeh: bodoviUspjeha,
            projekti: this.props.i.checkedProjekti ? bodoviProjekti : 0,
            nagrade: this.props.i.checkedNagrade || this.props.i.checkedNatjecanja ? bodoviNagrade : 0, // koristi se i za natjecanja
            razmjene: this.props.i.checkedRazmjene ? bodoviRazmjene : 0,
            objavljenRad: this.props.i.objavljenRad ? 10 : 0,
            invaliditet: this.props.wizard.checkedInvaliditet ? constData.invaliditetOptions.find(o => o.value === this.props.wizard.invaliditet).bodovi : 0,
            volontiranje: this.props.wizard.checkedVolontiranje ? constData.volontiranjeOptions.find(o => o.value === this.props.wizard.volontiranje).bodovi : 0
        }
        return bodovi;
    }
    izracunajSumuBodova() {
        var bodovi = this.izracunajBodove();
        var suma = 0;
        for (var key in bodovi) {
            suma += bodovi[key];
        }
        return suma;
    }

    // Handle nagrade
    handleClickOpenNagrade = () => {
        //this.setState({ openNagrade: true });
        this.props.saveIzracunProperty("openNagrade", true);
    };
    handleCloseNagrade = () => {
        //this.setState({ openNagrade: false });
        this.props.saveIzracunProperty("openNagrade", false);
    };
    handleDeleteNagrade = data => () => {
        this.props.deleteNagrada(data);
    };
    handleSaveNagrade = () => {
        var newKey = 0;
        for (var nagrada in this.props.i.nagrade) {
            if (nagrada.key > newKey) {
                newKey = nagrada.key + 1;
            }
        }
        var newNagrada = {
            key: newKey,
            tip: constData.nagradeOptions.find(o => o.value === this.props.i.currentNagradaTip).text,
            godina: this.props.i.currentNagradaGodina,
            naslov: this.props.i.currentNagradaNaziv
        }
        this.props.dodajNagradu(newNagrada);
        this.props.clearInputModals();
    };

    // Handle razmjene
    handleClickOpenRazmjene = () => {
        //this.setState({ openRazmjene: true });
        this.props.saveIzracunProperty("openRazmjene", true);
    };
    handleCloseRazmjene = () => {
        //this.setState({ openRazmjene: false });
        this.props.saveIzracunProperty("openRazmjene", false);
    };
    handleDeleteRazmjene = data => () => {
        this.props.deleteRazmjenu(data);
    };
    handleSaveRazmjene = () => {
        var newKey = 0;
        for (var razmjena in this.props.i.razmjene) {
            if (razmjena.key > newKey) {
                newKey = razmjena.key + 1;
            }
        }
        var newRazmjena = {
            key: newKey,
            tip: constData.razmjeneOptions.find(o => o.value === this.props.i.currentRazmjenaTip).text,
            godina: this.props.i.currentRazmjenaGodina,
            mjesto: this.props.i.currentRazmjenaMjesto,
            naziv: this.props.i.currentRazmjenaNaziv,
        }
        this.props.dodajRazmjenu(newRazmjena);
        this.props.clearInputModals();
    };

    // Handle projekti
    handleClickOpenProjekti = () => {
        //this.setState({ openProjekti: true });
        this.props.saveIzracunProperty("openProjekti", true);
    };
    handleCloseProjekti = () => {
        //this.setState({ openProjekti: false });
        this.props.saveIzracunProperty("openProjekti", false);
    };
    handleDeleteProjekti = data => () => {
        this.props.deleteProjekt(data);
    };
    handleSaveProjekti = () => {
        var newKey = 0;
        for (var projekt in this.props.i.projekti) {
            if (projekt.key > newKey) {
                newKey = projekt.key + 1;
            }
        }
        var newProject = {
            key: newKey,
            tip: constData.projektiOptions.find(o => o.value === this.props.i.currentProjectTip).text,
            godina: this.props.i.currentProjectGodina,
            naziv: this.props.i.currentProjectNaziv,
            donator: this.props.i.currentProjectDonator,
        }
        this.props.dodajProjekt(newProject);
        this.props.clearInputModals();
    };

    getChartOptions() {
        var bodovi = this.izracunajBodove();

        var ostvareniBodovi = [bodovi.uspjeh];
        var moguciBodovi = [60];
        var kategorije = ['Bodovi prema uspjehu'];

        if (this.props.i.checkedNagrade) {
            kategorije.push("Nagrade");
            ostvareniBodovi.push(bodovi.nagrade);
            moguciBodovi.push(10);
        }
        if (this.props.i.checkedProjekti) {
            kategorije.push("Projektne aktivnosti");
            ostvareniBodovi.push(bodovi.projekti);
            moguciBodovi.push(10);
        }
        if (this.props.i.checkedRazmjene) {
            kategorije.push("Razmjene");
            ostvareniBodovi.push(bodovi.razmjene);
            moguciBodovi.push(10);
        }
        if (this.props.i.objavljenRad) {
            kategorije.push("Objavljen rad");
            ostvareniBodovi.push(bodovi.objavljenRad);
            moguciBodovi.push(10);
        }

        if (this.props.wizard.checkedInvaliditet) {
            kategorije.push("Invaliditet");
            ostvareniBodovi.push(bodovi.invaliditet);
            moguciBodovi.push(15);
        }
        if (this.props.wizard.checkedVolontiranje) {
            kategorije.push("Volontiranje");
            ostvareniBodovi.push(bodovi.volontiranje);
            moguciBodovi.push(10);
        }

        var options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: ""
            },
            xAxis: {
                categories: kategorije,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Broj bodova',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Ostvareno',
                data: ostvareniBodovi
            }, {
                name: 'Moguće',
                data: moguciBodovi
            }]
        }
        return options;
    }

    showIzracunContent() {
        return (
            <div>
                <Container maxWidth="xl" className="my-4 py-3" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>




                        <Grid item xs={12} lg={4}>
                            <Switch
                                checked={this.props.i.checkedNagrade}
                                onChange={this.updateStateMaterialChecked('checkedNagrade')}
                                color="primary"
                                value="checkedNagrade"
                                label=""
                            />
                            <label>Imam nagrade na učilišnoj ili sveučilišnoj razini</label>
                            {this.props.i.checkedNagrade ? (
                                <div>
                                    <Fab color="primary" aria-label="add" size="small" onClick={this.handleClickOpenNagrade}>
                                        <AddIcon />
                                    </Fab>
                                    <p></p>
                                    <Dialog
                                        open={this.props.i.openNagrade}
                                        onClose={this.handleCloseNagrade}
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title">Dodaj nagradu</DialogTitle>
                                        <DialogContent>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentNagradaTip">Vrsta nagrade</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.i.currentNagradaTip}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentNagradaTip',
                                                        id: 'currentNagradaTip',
                                                    }}>
                                                    {constData.nagradeOptions.map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentNagradaGodina">Godina</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.i.currentNagradaGodina}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentNagradaGodina',
                                                        id: 'currentNagradaGodina',
                                                    }}>
                                                    {this.getLastYearsOptions().map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Naziv"
                                                    fullWidth={true}
                                                    value={this.props.i.currentNagradaNaziv}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentNagradaNaziv"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleCloseNagrade} color="primary">
                                                Odustani
                                                        </Button>
                                            <Button onClick={this.handleSaveNagrade} color="primary">
                                                Dodaj
                                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Paper>
                                        {this.props.i.nagrade.length === 0 ? (
                                            <p>Nemaš dodanih nagrada. Dodaj ih klikom na +.</p>
                                        ) : (
                                                this.props.i.nagrade.map(data => {
                                                    return (
                                                        <Chip
                                                            key={data.key}
                                                            label={`[${data.tip} - ${data.godina}] ${data.naslov}`}
                                                            onDelete={this.handleDeleteNagrade(data)}
                                                        />
                                                    );
                                                })
                                            )}
                                    </Paper>
                                </div>
                            ) : undefined}
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Switch
                                checked={this.props.i.checkedProjekti}
                                onChange={this.updateStateMaterialChecked('checkedProjekti')}
                                color="primary"
                                value="checkedProjekti"
                                label=""
                            />
                            <label>Sudjelovao sam u organizaciji/provođenju projekata</label>
                            {this.props.i.checkedProjekti ? (
                                <div>
                                    <Fab color="primary" aria-label="add" size="small" onClick={this.handleClickOpenProjekti}>
                                        <AddIcon />
                                    </Fab>
                                    <p></p>
                                    <Dialog
                                        open={this.props.i.openProjekti}
                                        onClose={this.handleCloseProjekti}
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title">Dodaj projektnu aktivnosti</DialogTitle>
                                        <DialogContent>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentProjectTip">Tip aktivnosti</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.i.currentProjectTip}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentProjectTip',
                                                        id: 'currentProjectTip',
                                                    }}>
                                                    {constData.projektiOptions.map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentProjectGodina">Godina</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.i.currentProjectGodina}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentProjectGodina',
                                                        id: 'currentProjectGodina',
                                                    }}>
                                                    {this.getLastYearsOptions().map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Donator"
                                                    fullWidth={true}
                                                    value={this.props.i.currentProjectDonator}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentProjectDonator"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Naziv"
                                                    fullWidth={true}
                                                    value={this.props.i.currentProjectNaziv}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentProjectNaziv"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleCloseProjekti} color="primary">
                                                Odustani
                                            </Button>
                                            <Button onClick={this.handleSaveProjekti} color="primary">
                                                Dodaj
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Paper>
                                        {this.props.i.projekti.length === 0 ? (
                                            <p>Nemaš dodanih projekata. Dodaj ih klikom na +.</p>
                                        ) : (
                                                this.props.i.projekti.map(data => {
                                                    return (
                                                        <Chip
                                                            key={data.key}
                                                            label={`[${data.tip} - ${data.godina}] ${data.naziv} (${data.donator})`}
                                                            onDelete={this.handleDeleteProjekti(data)}
                                                        />
                                                    );
                                                })
                                            )}

                                    </Paper>
                                </div>
                            ) : undefined}
                        </Grid>



                        <Grid item xs={12} lg={4}>
                            <Switch
                                checked={this.props.i.checkedRazmjene}
                                onChange={this.updateStateMaterialChecked('checkedRazmjene')}
                                color="primary"
                                value="checkedRazmjene"
                                label=""
                            />
                            <label>Sudjelovao sam na razmjenama</label>
                            {this.props.i.checkedRazmjene ? (
                                <div>
                                    <Fab color="primary" aria-label="add" size="small" onClick={this.handleClickOpenRazmjene}>
                                        <AddIcon />
                                    </Fab>
                                    <p></p>
                                    <Dialog
                                        open={this.props.i.openRazmjene}
                                        onClose={this.handleCloseRazmjene}
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title">Dodaj razmjenu</DialogTitle>
                                        <DialogContent>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentRazmjenaTip">Vrsta razmjene</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.i.currentNatjecanjeTip}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentRazmjenaTip',
                                                        id: 'currentRazmjenaTip',
                                                    }}>
                                                    {constData.razmjeneOptions.map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentRazmjenaGodina">Godina</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.i.currentRazmjenaGodina}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentRazmjenaGodina',
                                                        id: 'currentRazmjenaGodina',
                                                    }}>
                                                    {this.getLastYearsOptions().map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Mjesto/Grad razmjene"
                                                    fullWidth={true}
                                                    value={this.props.i.currentRazmjenaMjesto}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentRazmjenaMjesto"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Naziv razmjene"
                                                    fullWidth={true}
                                                    value={this.props.i.currentRazmjenaNaziv}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentRazmjenaNaziv"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleCloseRazmjene} color="primary">
                                                Odustani
                                                        </Button>
                                            <Button onClick={this.handleSaveRazmjene} color="primary">
                                                Dodaj
                                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Paper>
                                        {this.props.i.razmjene.length === 0 ? (
                                            <p>Nemaš dodanih razmjena. Dodaj ih klikom na +.</p>
                                        ) : (
                                                this.props.i.razmjene.map(data => {
                                                    return (
                                                        <Chip
                                                            key={data.key}
                                                            color="primary"
                                                            label={`[${data.tip}] ${data.nazvi} (${data.mjesto}, ${data.godina}.)`}
                                                            onDelete={this.handleDeleteRazmjene(data)}
                                                        />
                                                    );
                                                })
                                            )}
                                    </Paper>
                                </div>
                            ) : undefined}
                        </Grid>

                        <Grid item xs={12} lg={10}>
                            <Switch
                                checked={this.props.i.objavljenRad}
                                onChange={this.updateStateMaterialChecked('objavljenRad')}
                                color="primary"
                                value="objavljenRad"
                                label=""
                            />
                            <label>Imam objavljen rad</label>
                        </Grid>

                        <Grid item xs={12} lg={2}>
                            <Button variant="contained" color="primary" fullWidth={true} onClick={this.saveChanges}><SaveIcon /> Spremi</Button>
                        </Grid>

                    </Grid>
                </Container>

                <Container maxWidth="xl" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    <Typography className="mb-1 py-3"><b>UKUPAN BROJ BODOVA: {this.izracunajSumuBodova().toFixed(2)}</b></Typography>
                    <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} />
                </Container>
            </div>
        )
    }

    isPrijavaValid() {
        return this.props.wizard.prosjek > 4 &&
            this.props.wizard.prosjek <= 5 &&
            this.props.wizard.ects >= 55 &&
            !this.isMatura();
    }

    render() {
        return (
            <Container maxWidth="xl">
                {this.renderRedirect()}

                <Typography variant="h4" paragraph={true}>[IZRAČUN BODOVA] Kriteriji za dodjelu stipendija izvrsnim studentima</Typography>

                <b>* Dio podataka si unio kroz čarobnjak. Polja označena sa * su obavezna kako bi sustav izvršio potrebne izračune. Ukoliko ne postoje dodatne informacije koje želiš unijeti u ovaj obrazac, klikni izračunaj za prikaz bodova.</b>

                {this.isMatura() ? (
                    <div className="alert alert-danger custom-alert-style" role="alert">
                        Za prijavu na ovaj kriterij moraš imati završenu barem jednu godinu fakulteta.
                  </div>
                ) : (
                        <div>
                            {this.props.wizard.prosjek <= 4 ? (
                                <div className="alert alert-danger custom-alert-style" role="alert">
                                    Za prijavu na ovaj kriterij moraš imati prosjek ocjena iznad 4.
                          </div>
                            ) : undefined}

                            {this.props.wizard.ects < 55 ? (
                                <div className="alert alert-danger custom-alert-style" role="alert">
                                    Za prijavu na ovaj kriterij moraš imati minimalno 60 ECTS bodova, odnosno položene sve ispite iz prethodne godine.
                          </div>
                            ) : undefined}
                        </div>
                    )}

                {this.isPrijavaValid() ? (
                    this.showIzracunContent()
                ) : undefined}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        wizard: state.wizard,
        i: state.i
    }
};

const mapDispatchToProps = (dispatch) => ({
    saveIzracunProperty: (name, value) => dispatch({ type: SAVE_IZRACUN_PROPERTY, payload: { name: name, value: value } }),
    dodajNagradu: (nagrada) => dispatch({ type: ADD_NAGRADA, payload: nagrada }),
    deleteNagrada: (nagrada) => dispatch({ type: DELETE_NAGRADA, payload: nagrada }),
    dodajProjekt: (projekt) => dispatch({ type: ADD_PROJEKT, payload: projekt }),
    deleteProjekt: (projekt) => dispatch({ type: DELETE_PROJEKT, payload: projekt }),
    dodajRazmjenu: (razmjena) => dispatch({ type: ADD_RAZMJENA, payload: razmjena }),
    deleteRazmjenu: (razmjena) => dispatch({ type: DELETE_RAZMJENA, payload: razmjena }),
    clearInputModals: () => dispatch({ type: CLEAR_INPUT_MODALS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IzracunIzvrsni);