import React, { Component } from 'react';
import { connect } from 'react-redux';

import constData from '../../data/constants.json'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Button, Typography, InputLabel, Select, FormControl, FormHelperText, Switch, Container, TextField } from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { RESET_WIZARD, SAVE_WIZARD_DATA, NEXT_STEP, PREVIOUS_STEP } from '../../actions/wizardActions.js';


// https://codepen.io/chrgl86/pen/NRqzww

const styles = theme => ({
    wizard: {
        width: '100%',
    },
    stepper: {
        color: 'red'
    },
    stepLabel: {

    },
    button: {
        marginRight: theme.spacing.unit,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 320
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    selectField: {
        fontSize: 60
    }
});

class Carobnjak extends Component {
    isMatura() {
        return this.props.wizard.godina === "1" && (this.props.wizard.studij === constData.studijiOptions[1].value || this.props.wizard.studij === constData.studijiOptions[3].value || this.props.wizard.studij === constData.studijiOptions[4].value);
    }

    renderSteps() {
        switch (this.props.wizard.activeStep) {
            case 0:
                return (
                    <div>
                        <FormControl className={styles.formControl} error={!this.isValidInputStudij()} fullWidth={true}>
                            <InputLabel htmlFor="studij">Studij</InputLabel>
                            <Select
                                native
                                value={this.props.wizard.studij}
                                onChange={this.updateStateWithEvent.bind(this)}
                                inputProps={{
                                    name: 'studij',
                                    id: 'studij',
                                }}>
                                {constData.studijiOptions.map(option => {
                                    return (<option value={option.value}>{option.text}</option>);
                                })}
                            </Select>
                            <FormHelperText>{!this.isValidInputStudij() ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                        </FormControl>

                        <FormControl className={styles.formControl} error={!this.isValidInputGodina()} fullWidth={true}>
                            <InputLabel htmlFor="godina">Godina</InputLabel>
                            <Select
                                native
                                value={this.props.wizard.godina}
                                onChange={this.updateStateWithEvent.bind(this)}
                                inputProps={{
                                    name: 'godina',
                                    id: 'godina',
                                }}>
                                {constData.godinaOptions.map(option => {
                                    return (<option value={option.value}>{option.text}</option>);
                                })}
                            </Select>
                            <FormHelperText>{!this.isValidInputGodina() ? 'Odabir godine je obavezan.' : ''}</FormHelperText>
                        </FormControl>
                    </div>
                );
            case 1:
                if (this.isMatura()) {
                    return (
                        <div>
                            <FormControl className={styles.formControl} error={!this.isValidInputGodina()} fullWidth={true}>
                                <InputLabel htmlFor="matura">Razina mature</InputLabel>
                                <Select
                                    native
                                    value={this.props.wizard.matura}
                                    onChange={this.updateStateWithEvent.bind(this)}
                                    inputProps={{
                                        name: 'matura',
                                        id: 'matura',
                                    }}>
                                    {constData.maturaOptions.map(option => {
                                        return (<option value={option.value}>{option.text}</option>);
                                    })}
                                </Select>
                                <FormHelperText>{!this.isValidInputGodina() ? 'Odabir godine je obavezan.' : ''}</FormHelperText>
                            </FormControl>

                            <FormControl className={styles.formControl} error={!this.isValidProsjek()} fullWidth={true}>
                                <TextField
                                    id="outlined-number"
                                    label="Prosjek"
                                    value={this.props.wizard.prosjek}
                                    error={!this.isValidProsjek()}
                                    onChange={this.updateStateWithEvent.bind(this)}
                                    name="prosjek"
                                    type="number"
                                    className={styles.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "2", max: "5", step: "0.01" }}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <FormHelperText>{!this.isValidProsjek() ? 'Unos prosjeka ocjena je obavezan.' : ''}</FormHelperText>
                            </FormControl>
                        </div>
                    )
                }
                else {
                    return (
                        <div>
                            <FormControl className={styles.formControl} error={!this.isValidEcts()} fullWidth={true}>
                                <TextField
                                    id="outlined-number"
                                    label="ECTS bodovi"
                                    value={this.props.wizard.ects}
                                    onChange={this.updateStateWithEvent.bind(this)}
                                    name="ects"
                                    type="number"
                                    className={styles.textField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{ min: "0" }}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <FormHelperText>{!this.isValidEcts() ? 'Unos ECTS bodova je obavezan.' : ''}</FormHelperText>
                            </FormControl>

                            <FormControl className={styles.formControl} error={!this.isValidProsjek()} fullWidth={true}>
                                <TextField
                                    id="outlined-number"
                                    label="Prosjek"
                                    value={this.props.wizard.prosjek}
                                    error={!this.isValidProsjek()}
                                    onChange={this.updateStateWithEvent.bind(this)}
                                    name="prosjek"
                                    type="number"
                                    className={styles.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "2", max: "5", step: "0.01" }}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <FormHelperText>{!this.isValidProsjek() ? 'Unos prosjeka ocjena je obavezan.' : ''}</FormHelperText>
                            </FormControl>
                        </div>
                    )
                }
            case 2:
                return (
                    <div>
                        <div className="form-group">
                            <Switch
                                checked={this.props.wizard.checkedInvaliditet}
                                onChange={this.updateStateMaterialChecked('checkedInvaliditet')}
                                color="primary"
                                value="checkedInvaliditet"
                            />
                            <label>Student sam s invaliditetom</label>
                            {this.props.wizard.checkedInvaliditet ? (
                                <FormControl className={styles.formControl} error={!this.isValidInvaliditet()} fullWidth={true}>
                                    <InputLabel htmlFor="invaliditet">Invaliditet</InputLabel>
                                    <Select
                                        native
                                        value={this.props.wizard.invaliditet}
                                        onChange={this.updateStateWithEvent.bind(this)}
                                        inputProps={{
                                            name: 'invaliditet',
                                            id: 'invaliditet',
                                        }}>
                                        {constData.invaliditetOptions.map(option => {
                                            return (<option value={option.value}>{option.text}</option>);
                                        })}
                                    </Select>
                                    <FormHelperText>{!this.isValidInvaliditet() ? 'Ako si student s invaliditetom, moraš unijeti dodatne informacije.' : ''}</FormHelperText>
                                </FormControl>
                            ) : undefined}
                        </div>

                        <div className="form-group">
                            <Switch
                                checked={this.props.wizard.checkedVolontiranje}
                                onChange={this.updateStateMaterialChecked('checkedVolontiranje')}
                                color="primary"
                                value="checkedVolontiranje"
                                label=""
                            />
                            <label>Volontirao sam na manifestacijama važnim za Grad Lepoglavu</label>
                            {this.props.wizard.checkedVolontiranje ? (
                                <FormControl className={styles.formControl} error={!this.isValidVolontiranje()} fullWidth={true}>
                                    <InputLabel htmlFor="volontiranje">Volontiranje</InputLabel>
                                    <Select
                                        native
                                        value={this.props.wizard.volontiranje}
                                        onChange={this.updateStateWithEvent.bind(this)}
                                        inputProps={{
                                            name: 'volontiranje',
                                            id: 'volontiranje',
                                        }}>
                                        {constData.volontiranjeOptions.map(option => {
                                            return (<option value={option.value}>{option.text}</option>);
                                        })}
                                        })}
                                </Select>
                                    <FormHelperText>{!this.isValidVolontiranje() ? 'Ako si volontirao, moraš unijeti dodatne informacije' : ''}</FormHelperText>
                                </FormControl>
                            ) : undefined}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Typography variant="h4" className="mb-4">Pregled podataka:</Typography>

                        <Typography variant="h6">
                            Upisani studij: <b>{constData.studijiOptions.find(o => o.value === this.props.wizard.studij).text}</b>
                        </Typography>

                        <Typography variant="h6">
                            Upisana godina: <b>{constData.godinaOptions.find(o => o.value === this.props.wizard.godina).text}</b>
                        </Typography>

                        <Typography variant="h6">
                            Prosjek: <b>{this.props.wizard.prosjek}</b>
                        </Typography>

                        <Typography variant="h6">
                            {this.isMatura() ? (
                                <span>Razina mature: <b>{constData.maturaOptions.find(o => o.value === this.props.wizard.matura).text}</b></span>
                            ) : (
                                    <span>Broj ECTS bodova: <b>{this.props.wizard.ects}</b></span>
                                )}
                        </Typography>

                        <Typography variant="h6">
                            Invaliditet: <b>{this.props.wizard.checkedInvaliditet ? constData.invaliditetOptions.find(o => o.value === this.props.wizard.invaliditet).text : 'Nisam student s invaliditetom.'}</b>
                        </Typography>

                        <Typography variant="h6">
                            Volonterski angažman: <b>{this.props.wizard.checkedVolontiranje ? constData.volontiranjeOptions.find(o => o.value === this.props.wizard.volontiranje).text : 'Nisam volontirao na manifestacijama od važnosti za Grad Lepoglavu.'}</b>
                        </Typography>

                        <Typography variant="h6" className="mt-4">
                            <i>Podaci su zabilježeni. Možeš nastaviti na izračun bodova po kriterijima.</i>
                        </Typography>
                    </div>
                );
            default:
                break;
        }
    }

    isStepFailed = step => {
        return this.props.wizard.stepsWithError[step];
    };

    isValidInputStudij() {
        return this.props.wizard.studij !== constData.studijiOptions[0].value &&
            this.props.wizard.studij !== "";
    }
    isValidInputGodina() {
        return this.props.wizard.godina !== constData.godinaOptions[0].value &&
            this.props.wizard.godina !== "";
    }
    isValidProsjek() {
        return this.props.wizard.prosjek !== "" &&
            this.props.wizard.prosjek >= 2 &&
            this.props.wizard.prosjek <= 5;
    }
    isValidEcts() {
        return this.props.wizard.ects !== "" &&
            this.props.wizard.ects >= 0;
    }
    isValidInvaliditet() {
        if (!this.props.wizard.checkedInvaliditet) {
            return true;
        }
        else {
            return this.props.wizard.invaliditet !== "";
        }
    }
    isValidVolontiranje() {
        if (!this.props.wizard.checkedVolontiranje) {
            return true;
        }
        else {
            return this.props.wizard.volontiranje !== "";
        }
    }

    validateUserInput = () => {
        var errors = this.props.wizard.stepsWithError;

        switch (this.props.wizard.activeStep) {
            case 0:
                errors[this.props.wizard.activeStep] = !(this.isValidInputStudij() && this.isValidInputGodina());
                break;
            case 1:
                errors[this.props.wizard.activeStep] = !(this.isValidProsjek());
                break;
            case 2:
                errors[this.props.wizard.activeStep] = !(this.isValidInvaliditet() && this.isValidVolontiranje());
                break;
            default:
                break;
        }

        //this.setState({ stepsWithError: errors });
        this.props.saveWizardProperty("stepsWithError", errors);
    }

    handleNextStep = () => {
        this.validateUserInput();
        if (this.props.wizard.stepsWithError[this.props.wizard.activeStep]) {
            return;
        }
        this.props.handleNext();
        this.props.saveStateToCookie();
    };

    handleWizardReset = () => {
        document.cookie = `stipendijeData=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
        this.props.resetWizard();
    }

    updateStateWithEvent = (event) => {
        //this.setState({ [event.target.name]: event.target.value });
        this.props.saveWizardProperty(event.target.name, event.target.value);
    }

    updateStateMaterialChecked = name => event => {
        //this.setState({ [name]: event.target.checked });
        this.props.saveWizardProperty(name, event.target.checked);
    }

    render() {
        const { classes } = this.props;
        const { activeStep, steps } = this.props.wizard;

        return (
            <Container maxWidth="md">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const props = {};
                        const labelProps = {};
                        labelProps.className = classes.stepLabel;
                        if (this.isStepFailed(index)) {
                            labelProps.error = true;
                        }
                        return (
                            <Step key={label} {...props}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div className="py-2">
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}></Typography>
                            <Button variant="contained" color="secondary" onClick={this.handleWizardReset} startIcon={<DeleteForeverIcon />}>Reset</Button>
                        </div>
                    ) : (
                            <div>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={this.props.handleBack}
                                        className={classes.backButton}>
                                        Prethodni
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={this.handleNextStep}>
                                        {activeStep === steps.length - 1 ? 'Završi' : 'Sljedeći'}
                                    </Button>
                                </div>

                            </div>
                        )}
                </div>
                <Container maxWidth="xl" className="mt-5 py-4" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    {this.renderSteps()}
                </Container>
            </Container>
        );
    }

}

Carobnjak.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        wizard: state.wizard
    }
}

const mapDispatchToProps = (dispatch) => ({
    handleBack: () => dispatch({ type: PREVIOUS_STEP }),
    handleNext: () => dispatch({ type: NEXT_STEP }),
    resetWizard: () => dispatch({ type: RESET_WIZARD }),
    saveWizardProperty: (name, value) => dispatch({ type: SAVE_WIZARD_DATA, payload: { name: name, value: value } })
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Carobnjak));