import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { Link } from 'react-router-dom';

import numSlika from "./components/AboutComponent/num-logo.png";
import './App.css';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, AppBar, Toolbar, List, CssBaseline, Typography, Divider, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import BuildIcon from '@material-ui/icons/Build';

import Home from './components/HomeComponent/Home.jsx';
import About from './components/AboutComponent/About.jsx';
import Contact from './components/ContactComponent/Contact.jsx';

import KriterijSocioEkonomski from "./components/KriterijComponent/KriterijSocioEkonomski.jsx";
import KriterijDeficitarni from "./components/KriterijComponent/KriterijDeficitarni.jsx";
import KriterijIzvrsni from "./components/KriterijComponent/KriterijIzvrsni.jsx";

import IzracunSocioEkonomski from './components/Izracun/IzracunSocioEkonomski.jsx';
import IzracunDeficitarni from './components/Izracun/IzracunDeficitarni.jsx';
import IzracunIzvrsni from './components/Izracun/IzracunIzvrsni.jsx';

import Usporedi from './components/Usporedi/Usporedi.jsx';
import Carobnjak from "./components/Wizard/Carobnjak.jsx";

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const menuItems = [
  {
    url: "/",
    icon: <HomeIcon />,
    text: "Početna"
  },
  {
    url: "separator"
  },
  {
    url: "title",
    text: "KRITERIJI"
  },
  {
    url: "/kriterij/socio-ekonomski",
    icon: <LooksOneIcon />,
    text: "Socio-ekonomski"
  },
  {
    url: "/kriterij/izvrsni",
    icon: <LooksTwoIcon />,
    text: "Izvrsni"
  },
  {
    url: "separator"
  },
  {
    url: "title",
    text: "IZRAČUN"
  },
  {
    url: "/izracun/socio-ekonomski",
    icon: <Filter1Icon />,
    text: "Socio-ekonomski"
  },
  {
    url: "/izracun/izvrsni",
    icon: <Filter2Icon />,
    text: "Izvrsni"
  },
  {
    url: "/carobnjak",
    icon: <BuildIcon />,
    text: "Čarobnjak za postavke"
  },
  {
    url: "separator"
  },
  {
    url: "/kontakt",
    icon: <MailIcon />,
    text: "Kontakt"
  },
  {
    url: "/about",
    icon: <InfoIcon />,
    text: "About"
  },
  {
    url: "separator"
  },
];

function App(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Router>

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">
              Stipendije za studente s područja grada Lepoglave
                  </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>

          {open &&
            <div style={{ textAlign: "center" }}>
              <img src={numSlika} style={{ width: "60%" }} alt="NUM Logo" />
            </div>
          }

          <List>
            {menuItems.map((item, index) => (
              item.url === "separator" ? <Divider /> : item.url !== 'title' ?
                <Link to={item.url} style={{ textDecoration: 'none', color: "#000" }}>
                  <ListItem button key={index}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                </Link> : open ? <ListItem style={{ fontWeight: "bold" }}> <ListItemText primary={item.text} /> </ListItem> : ""
            ))}
          </List>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Route exact path="/" component={Home} />

          <Route path="/kriterij/socio-ekonomski" component={KriterijSocioEkonomski} />
          <Route path="/kriterij/deficitarni" component={KriterijDeficitarni} />
          <Route path="/kriterij/izvrsni" component={KriterijIzvrsni} />


          <Route path="/izracun/socio-ekonomski" component={() => <IzracunSocioEkonomski saveStateToCookie={props.saveStateToCookie} />} />
          <Route path="/izracun/deficitarni" component={() => <IzracunDeficitarni saveStateToCookie={props.saveStateToCookie} />} />
          <Route path="/izracun/izvrsni" component={() => <IzracunIzvrsni saveStateToCookie={props.saveStateToCookie} />} />

          <Route path="/usporedi" component={() => <Usporedi />} />
          <Route path="/carobnjak" component={() => <Carobnjak saveStateToCookie={props.saveStateToCookie} />} />

          <Route path="/kontakt" component={Contact} />
          <Route path="/about" component={About} />

        </main>
      </div>

    </Router>
  );
}

export default App;