import React from 'react';

import numLogo from "./num-logo.png";
import davorin from "./davorin.jpg";
import robert from "./robert.jpg";
import reduxLogo from './redux.svg';
import materialLogo from './material-ui-logo.svg';
import highchartsLogo from './highcharts-logo.png';
import mathJaxLogo from './mj-logo.png';

import { Container, Grid, Typography, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import PublicIcon from '@material-ui/icons/Public';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import Quote from '../Quote/Quote.jsx';

export default function About() {

    return (
        <div>
            <Container maxWidth="lg" >
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} lg={3} className="mb-5">
                        <div style={{ textAlign: "center" }}>
                            <img src={numLogo} style={{ width: "50%" }} alt="" />
                        </div>


                        <ListItem className="mt-3">
                            <ListItemIcon>
                                <PublicIcon />
                            </ListItemIcon>
                            <a href="https://www.num.hr" target="_blank" rel="noopener noreferrer">
                                <ListItemText primary="www.num.hr" />
                            </a>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <FacebookIcon />
                            </ListItemIcon>
                            <a href="https://www.facebook.com/num.lepoglava" target="_blank" rel="noopener noreferrer">
                                <ListItemText primary="num.lepoglava" />
                            </a>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <MailOutlineIcon />
                            </ListItemIcon>
                            <a href="mailto:info@num.hr" target="_blank" rel="noopener noreferrer">
                                <ListItemText primary="info@num.hr" />
                            </a>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <PhoneIphoneIcon />
                            </ListItemIcon>
                            <a href="tel:+385 97 623 9589" target="_blank" rel="noopener noreferrer">
                                <ListItemText primary="+385 97 623 9589" />
                            </a>
                        </ListItem>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Typography variant="h4" paragraph={true}>Nezavisna udruga mladih</Typography>
                        <Typography paragraph={true}>Nezavisna udruga mladih klasična je i standardna organizacija mladih koju su osnovali mladi, vode mladi i koja radi za mlade.</Typography>

                        <Typography>
                            <ul>
                                <li>Udruga je osnovana <strong>25.05.2002.</strong></li>
                                <li>17 osnivača</li>
                                <li>
                                    Donatori na Europskoj razini [<strong>Agencija za mobilnost i programe EU</strong>]
                                    <ul>
                                        <li>
                                            Erasmus+
                                        <ul>
                                                <li>Nositelji: 7 projekata (2015. do danas)</li>
                                                <li>Partneri: 19 projekata (2015. do danas)</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Youth in action
                                        <ul>
                                                <li>Nositelji: 4 projekata (2013. i 2014.)</li>
                                                <li>Partneri: 14 projekata (2012. do 2014.)</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Donatori na lokalnoj, regionalnoj i nacionalnoj razini
                                    <ul>
                                        <li>[<strong>Nacionalna zaklada za razvoj civilnog društva</strong>] 3 projekta (2013. do danas)</li>
                                        <li>[<strong>Zaklada Zamah</strong>] 3 projekta (2013. do danas)</li>
                                        <li>[<strong>Ministarstva</strong>] 4 projekta (2013. do danas)</li>
                                        <li>[<strong>Grad Lepoglava</strong>] 7 projekata (2013. do danas)</li>
                                        <li>[<strong>Varaždinska županija</strong>] 2 projekta (2013. do danas)</li>
                                    </ul>
                                </li>
                            </ul>
                        </Typography>
                </Grid>
                </Grid>
            <hr />
            </Container>

        <Container maxWidth="lg" className="mt-5">
            <Grid container direction="row" justify="center">
                <Grid item xs={12} lg={3} className="mb-5">
                    <div style={{ textAlign: "center" }}>
                        <img src={davorin} style={{ width: "50%", borderRadius: "50%" }} alt="" />
                    </div>

                    <Typography variant="h6" paragraph={true} className="mt-5">Tehnologije</Typography>

                    <ListItem>
                        <ListItemIcon>
                            <i class="fab fa-js-square"></i>
                        </ListItemIcon>
                        <ListItemText primary="Javascript" />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <i class="fab fa-react"></i>
                        </ListItemIcon>
                        <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                            <ListItemText primary="ReactJS" />
                        </a>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <img src={reduxLogo} style={{ width: "16px" }} alt="Redux" />
                        </ListItemIcon>
                        <a href="https://redux.js.org/" target="_blank" rel="noopener noreferrer">
                            <ListItemText primary="Redux" />
                        </a>
                    </ListItem>

                    <Typography variant="h6" paragraph={true} className="mt-2">Credits</Typography>

                    <ListItem>
                        <ListItemIcon>
                            <img src={materialLogo} style={{ width: "16px" }} alt="Material UI" />
                        </ListItemIcon>
                        <a href="https://material-ui.com" target="_blank" rel="noopener noreferrer">
                            <ListItemText primary="Material UI" />
                        </a>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <img src={highchartsLogo} style={{ width: "16px" }} alt="Highcharts" />
                        </ListItemIcon>
                        <a href="https://www.highcharts.com/products/highcharts" target="_blank" rel="noopener noreferrer">
                            <ListItemText primary="Highcharts" secondary="CC BY-NC 3.0" />
                        </a>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <img src={mathJaxLogo} style={{ width: "16px" }} alt="MathJax" />
                        </ListItemIcon>
                        <a href="https://www.mathjax.org" target="_blank" rel="noopener noreferrer">
                            <ListItemText primary="MathJax" />
                        </a>
                    </ListItem>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Typography variant="h4" paragraph={true}>Davorin Špičko</Typography>
                    <Typography variant="h6" paragraph={true}>O autoru</Typography>

                    <Typography paragraph={true} style={{ textAlign: "justify" }}>U radu <b>Nezavisne udruge mladih</b> povremeno je sudjelovao od 2006. godine kao volonter. Punopravan član postaje tijekom 2012. godine te se aktivnije uključuje u rad Tijela i organizacijsku strukturu. NUM ga je 2014. godine kandidirao za mandat u Savjetu mladih grada Lepoglave.</Typography>
                    <Typography paragraph={true} style={{ textAlign: "justify" }}>Kao potpredsjednik Savjeta mladih grada Lepoglave u razdoblju 2014. do 2016. godine, aktivno je bio uključen u promjenu <b>Kriterija za dodjelu stipendija studentima s područja grada Lepoglave</b> kako bi se način dodjele stipendija ujednačio sa bolonjskim procesom. Sudjelovanje studenata je bio pokazatelj da se gradski procesi otvaraju prema građanima, koji su na taj način postali nositelji promjena. Uključivanje građana u razvoj procesa zainteresiralo je <i>Roberta Posavca</i> u knjizi <i>Pank poduzetnici</i>:</Typography>
                    <Quote authorImage={robert} author="Robert Posavec, Pank poduzetnici, 2018., ACT Grupa, str. 124." text="Participacija građana je općenito na niskoj razini i svih se ovih godina vrlo malo povećala. Sudjelovanje građana u planiranju proračuna svedeno je na minimum, a taj minimum tamo gdje i postoji čisto je deklarativan. Kultura sudjelovanja ne postoji, a ni okvir nije nimalo poticajan. To je samo preslika funkcioniranja čitavog društva. Vrlo je malo ljudi spremno proučavati strategije i programe, tražiti informacije i sudjelovati u raspravama i kreiranju javnih strategija i programa te proračuna. Čak i kad se nađe takav luđak, političari ga odbace jer nije član SDP-a, HNS-a ili HDZ-a. Jedina svijetla točka i odmak od takve prakse na sjeveru Hrvatske jest Grad Lepoglava, koja je otvorila mnoge procese i stalno poziva građane da reagiraju i sudjeluju. Studenti s područja grada su 2017. sami kreirali natječaj za stipendije. Tu je mnogo učinio Hrvoje Kovač, koji je dogradonačelnik Lepoglave, a prije toga je bio aktivan sudionik civilnog društva i zaposlen u ACT-u na Programu JAKO."  />
                    <Typography paragraph={true} style={{ textAlign: "justify" }}>Aplikaciju za izračun izradio je iz želje za upoznavanjem novih tehnologija, pri čemu smatra da je najbolji način primjena na realnim projektima, pa je aplikaciju izradio pomoću <i>frontend</i> tehnologija unatoč činjenici da radi kao Java <i>backend</i> developer.</Typography>
                </Grid>
            </Grid>
        </Container>

        </div >
    );
}