export const SAVE_IZRACUN_PROPERTY = "SAVE_IZRACUN_PROPERTY";
export const ADD_PROJEKT = "ADD_PROJEKT";
export const DELETE_PROJEKT = "DELETE_PROJEKT";
export const ADD_NAGRADA = "ADD_NAGRADA";
export const DELETE_NAGRADA = "DELETE_NAGRADA";
export const ADD_RAZMJENA = "ADD_RAZMJENA";
export const DELETE_RAZMJENA = "DELETE_RAZMJENA";
export const CLEAR_INPUT_MODALS = "CLEAR_INPUT_MODALS";

export function saveIzracunProperty(property){
    return {
        type: SAVE_IZRACUN_PROPERTY,
        payload: {
            name: property.name,
            value: property.value
        }
    }
}

export function addNagrada(nagrada){
    return {
        type: ADD_NAGRADA,
        payload: nagrada
    }
}

export function deleteNagrada(nagrada){
    return {
        type: DELETE_NAGRADA,
        payload: nagrada
    }
}

export function addProjekt(projekt){
    return {
        type: ADD_PROJEKT,
        payload: projekt
    }
}

export function deleteProjekt(projekt){
    return {
        type: DELETE_PROJEKT,
        payload: projekt
    }
}

export function addRazmjena(razmjena){
    return {
        type: ADD_RAZMJENA,
        payload: razmjena
    }
}

export function deleteRazmjena(razmjena){
    return {
        type: DELETE_RAZMJENA,
        payload: razmjena
    }
}

export function clearInputModals(){
    return {
        type: CLEAR_INPUT_MODALS
    }
}