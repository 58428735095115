export const SAVE_IZRACUN_PROPERTY = "SAVE_IZRACUN_PROPERTY";

export function saveIzracunProperty(property){
    return {
        type: SAVE_IZRACUN_PROPERTY,
        payload: {
            name: property.name,
            value: property.value
        }
    }
}