import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import constData from '../../data/constants.json'

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import { SAVE_IZRACUN_PROPERTY, ADD_NATJECANJE, DELETE_NATJECANJE, ADD_PROJEKT, DELETE_PROJEKT, ADD_NAGRADA, DELETE_NAGRADA, CLEAR_INPUT_MODALS } from '../../actions/dActions';
import { Container, Typography, Grid } from '@material-ui/core';

class IzracunDeficitarni extends Component {

    renderRedirect() {
        if (!this.props.wizard.isWizardCompleted) {
            return (<Redirect to='/carobnjak' />);
        }
    }

    saveChanges = e => {
        e.preventDefault();
        if (!this.isValidBrojClanova() && !this.isValidUkupniPrihod()) {
            return;
        }
        this.props.saveIzracunProperty("sumaBodova", this.izracunajSumuBodova());
        this.props.saveStateToCookie();
    }

    updateStateWithEvent = (event) => {
        //this.setState({ [event.target.name]: event.target.value });
        this.props.saveIzracunProperty(event.target.name, event.target.value);
    }

    updateStateMaterialChecked = name => event => {
        //this.setState({ [name]: event.target.checked });
        this.props.saveIzracunProperty(name, event.target.checked);
    }

    isValidUkupniPrihod() {
        return this.props.d.ukupniPrihod !== "" &&
            this.props.d.ukupniPrihod >= 0
    }
    isValidBrojClanova() {
        return this.props.d.brojClanova !== "" &&
            this.props.d.brojClanova >= 1
    }

    isMatura() {
        return this.props.wizard.godina === "1" && (
            this.props.wizard.studij === constData.studijiOptions[1].value ||
            this.props.wizard.studij === constData.studijiOptions[3].value ||
            this.props.wizard.studij === constData.studijiOptions[4].value);
    }

    getLastYearsOptions() {
        var currentYear = new Date().getFullYear();
        var years = [{
            value: "",
            text: ""
        }];
        for (var i = currentYear; i >= currentYear - 6; i--) {
            years.push({
                value: i,
                text: i
            });
        }
        return years;
    }

    izracunajBodove() {
        // PRIHOD
        var bodoviPrihoda = 0;
        var prihodPoClanu = this.props.d.ukupniPrihod / this.props.d.brojClanova;
        if (prihodPoClanu < 750) {
            bodoviPrihoda = 30;
        }
        else if (prihodPoClanu > 2500) {
            bodoviPrihoda = 0;
        }
        else {
            bodoviPrihoda = (2500 - prihodPoClanu) * 30 / 1750;
        }

        // USPJEH
        var bodoviUspjeha = 0;
        // matura
        if (this.isMatura()) {
            var razina = constData.maturaOptions.find(o => o.value === this.props.wizard.matura).razina;
            bodoviUspjeha = 15 * (this.props.wizard.prosjek - 3) * razina;
        }
        else { // faks
            bodoviUspjeha = (this.props.wizard.prosjek * this.props.wizard.ects) / 3 - 60;
        }
        if (bodoviUspjeha > 40) {
            bodoviUspjeha = 40;
        }

        // PROJEKTI
        var bodoviProjekti = 0;
        for (let index in this.props.d.projekti) {
            let bodovi = constData.projektiOptions.find(o => o.text === this.props.d.projekti[index].tip).bodovi;
            if (bodovi !== undefined && bodovi > 0) {
                bodoviProjekti += bodovi;
            }
        }
        if (bodoviProjekti > 10) {
            bodoviProjekti = 10;
        }

        // NAGRADE
        var bodoviNagrade = 0;
        if (this.isMatura()) {
            for (let index in this.props.d.natjecanja) {
                let bodovi = constData.natjecanjaOptions.find(o => o.text === this.props.d.natjecanja[index].tip).bodovi;
                if (bodovi !== undefined && bodovi > 0) {
                    bodoviNagrade += bodovi;
                }
            }
        }
        else {
            for (let index in this.props.d.nagrade) {
                let bodovi = constData.nagradeOptions.find(o => o.text === this.props.d.nagrade[index].tip).bodovi;
                if (bodovi !== undefined && bodovi > 0) {
                    bodoviNagrade += bodovi;
                }
            }
        }
        if (bodoviNagrade > 10) {
            bodoviNagrade = 10;
        }

        var bodovi = {
            prihod: bodoviPrihoda,
            samohraniRoditelj: this.props.d.samohraniRoditelj ? 5 : 0,
            roditelj: this.props.d.roditelj ? 5 : 0,
            uspjeh: bodoviUspjeha,
            projekti: this.props.d.checkedProjekti ? bodoviProjekti : 0,
            nagrade: this.props.d.checkedNagrade || this.props.d.checkedNatjecanja ? bodoviNagrade : 0, // koristi se i za natjecanja
            invaliditet: this.props.wizard.checkedInvaliditet ? constData.invaliditetOptions.find(o => o.value === this.props.wizard.invaliditet).bodovi : 0,
            volontiranje: this.props.wizard.checkedVolontiranje ? constData.volontiranjeOptions.find(o => o.value === this.props.wizard.volontiranje).bodovi : 0
        }
        return bodovi;
    }
    izracunajSumuBodova() {
        var bodovi = this.izracunajBodove();
        var suma = 0;
        for (var key in bodovi) {
            suma += bodovi[key];
        }
        return suma;
    }

    // Handle nagrade
    handleClickOpenNagrade = () => {
        //this.setState({ openNagrade: true });
        this.props.saveIzracunProperty("openNagrade", true);
    };
    handleCloseNagrade = () => {
        //this.setState({ openNagrade: false });
        this.props.saveIzracunProperty("openNagrade", false);
    };
    handleDeleteNagrade = data => () => {
        this.props.deleteNagrada(data);
    };
    handleSaveNagrade = () => {
        var newKey = 0;
        for (var nagrada in this.props.d.nagrade) {
            if (nagrada.key > newKey) {
                newKey = nagrada.key + 1;
            }
        }
        var newNagrada = {
            key: newKey,
            tip: constData.nagradeOptions.find(o => o.value === this.props.d.currentNagradaTip).text,
            godina: this.props.d.currentNagradaGodina,
            naslov: this.props.d.currentNagradaNaziv
        }
        this.props.dodajNagradu(newNagrada);
        this.props.clearInputModals();
    };

    // Handle natjecanja
    handleClickOpenNatjecanja = () => {
        //this.setState({ openNatjecanja: true });
        this.props.saveIzracunProperty("openNatjecanja", true);
    };
    handleCloseNatjecanja = () => {
        //this.setState({ openNatjecanja: false });
        this.props.saveIzracunProperty("openNatjecanja", false);
    };
    handleDeleteNatjecanja = data => () => {
        this.props.deleteNatjecanje(data);
    }
    handleSaveNatjecanja = () => {
        var newKey = 0;
        for (var natjecanje in this.props.d.natjecanja) {
            if (natjecanje.key > newKey) {
                newKey = natjecanje.key + 1;
            }
        }
        var newNatjecanje = {
            key: newKey,
            tip: constData.natjecanjaOptions.find(o => o.value === this.props.d.currentNatjecanjeTip).text,
            godina: this.props.d.currentNatjecanjeGodina,
            mjesto: this.props.d.currentNatjecanjeMjesto,
            predmet: this.props.d.currentNatjecanjePredmet,
        }

        this.props.dodajNagradu(newNatjecanje);
        this.props.clearInputModals();
    };

    // Handle projekti
    handleClickOpenProjekti = () => {
        //this.setState({ openProjekti: true });
        this.props.saveIzracunProperty("openProjekti", true);
    };
    handleCloseProjekti = () => {
        //this.setState({ openProjekti: false });
        this.props.saveIzracunProperty("openProjekti", false);
    };
    handleDeleteProjekti = data => () => {
        this.props.deleteProjekt(data);
    };
    handleSaveProjekti = () => {
        var newKey = 0;
        for (var projekt in this.props.d.projekti) {
            if (projekt.key > newKey) {
                newKey = projekt.key + 1;
            }
        }
        var newProject = {
            key: newKey,
            tip: constData.projektiOptions.find(o => o.value === this.props.d.currentProjectTip).text,
            godina: this.props.d.currentProjectGodina,
            naziv: this.props.d.currentProjectNaziv,
            donator: this.props.d.currentProjectDonator,
        }
        this.props.dodajProjekt(newProject);
        this.props.clearInputModals();
    };

    getChartOptions() {
        var bodovi = this.izracunajBodove();

        var ostvareniBodovi = [bodovi.prihod, bodovi.uspjeh];
        var moguciBodovi = [30, 40];
        var kategorije = ['Bodovi prema prihodu', 'Bodovi prema uspjehu'];

        if (this.props.d.samohraniRoditelj) {
            kategorije.push("Student živi samo s jednim roditeljem");
            ostvareniBodovi.push(bodovi.samohraniRoditelj);
            moguciBodovi.push(5);
        }
        if (this.props.d.roditelj) {
            kategorije.push("Student je roditelj");
            ostvareniBodovi.push(bodovi.roditelj);
            moguciBodovi.push(5);
        }

        if (this.props.d.checkedNagrade) {
            kategorije.push("Nagrade");
            ostvareniBodovi.push(bodovi.nagrade);
            moguciBodovi.push(10);
        }
        if (this.props.d.checkedNatjecanja) {
            kategorije.push("Natjecanja");
            ostvareniBodovi.push(bodovi.nagrade);
            moguciBodovi.push(10);
        }
        if (this.props.d.checkedProjekti) {
            kategorije.push("Projektne aktivnosti");
            ostvareniBodovi.push(bodovi.projekti);
            moguciBodovi.push(10);
        }

        if (this.props.wizard.checkedInvaliditet) {
            kategorije.push("Invaliditet");
            ostvareniBodovi.push(bodovi.invaliditet);
            moguciBodovi.push(15);
        }
        if (this.props.wizard.checkedVolontiranje) {
            kategorije.push("Volontiranje");
            ostvareniBodovi.push(bodovi.volontiranje);
            moguciBodovi.push(10);
        }

        var options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: ""
            },
            xAxis: {
                categories: kategorije,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Broj bodova',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Ostvareno',
                data: ostvareniBodovi
            }, {
                name: 'Moguće',
                data: moguciBodovi
            }]
        }
        return options;
    }

    showIzracunContent() {
        return (
            <div>
                <Container maxWidth="xl" className="my-4 py-3" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} lg={2}>
                            <TextField
                                id="outlined-number"
                                label="Ukupni prihod"
                                fullWidth={true}
                                value={this.props.d.ukupniPrihod}
                                error={!this.isValidUkupniPrihod()}
                                onChange={this.updateStateWithEvent.bind(this)}
                                name="ukupniPrihod"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: "0", step: "50" }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <TextField
                                id="outlined-number"
                                label="Broj članova kućanstva"
                                fullWidth={true}
                                value={this.props.d.brojClanova}
                                error={!this.isValidBrojClanova()}
                                onChange={this.updateStateWithEvent.bind(this)}
                                name="brojClanova"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: "1" }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} lg={2}>
                            <Switch
                                checked={this.props.d.samohraniRoditelj}
                                onChange={this.updateStateMaterialChecked('samohraniRoditelj')}
                                color="primary"
                                value="samohraniRoditelj"
                                label=""
                            />
                            <label>Samohrani roditelj</label>
                        </Grid>

                        <Grid item xs={12} lg={2}>
                            <Switch
                                checked={this.props.d.roditelj}
                                onChange={this.updateStateMaterialChecked('roditelj')}
                                color="primary"
                                value="roditelj"
                                label=""
                            />
                            <label>Student je roditelj</label>
                        </Grid>


                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                        <Grid item xs={12} lg={5}>
                            {this.isMatura() ? (
                                <div className="form-group">
                                    <Switch
                                        checked={this.props.d.checkedNatjecanja}
                                        onChange={this.updateStateMaterialChecked('checkedNatjecanja')}
                                        color="primary"
                                        value="checkedNatjecanja"
                                        label=""
                                    />
                                    <label>Sudjelovao sam na natjecanjima</label>
                                    {this.props.d.checkedNatjecanja ? (
                                        <div>
                                            <Fab color="primary" aria-label="add" size="small" onClick={this.handleClickOpenNatjecanja}>
                                                <AddIcon />
                                            </Fab>
                                            <p></p>
                                            <Dialog
                                                open={this.props.d.openNatjecanja}
                                                onClose={this.handleCloseNatjecanja}
                                                aria-labelledby="form-dialog-title"
                                            >
                                                <DialogTitle id="form-dialog-title">Dodaj natjecanje</DialogTitle>
                                                <DialogContent>
                                                    <FormControl error={false} fullWidth={true}>
                                                        <InputLabel htmlFor="currentNatjecanjeTip">Razina natjecanja</InputLabel>
                                                        <Select
                                                            native
                                                            value={this.props.d.currentNatjecanjeTip}
                                                            onChange={this.updateStateWithEvent.bind(this)}
                                                            inputProps={{
                                                                name: 'currentNatjecanjeTip',
                                                                id: 'currentNatjecanjeTip',
                                                            }}>
                                                            {constData.natjecanjaOptions.map(option => {
                                                                return (<option value={option.value}>{option.text}</option>);
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                    </FormControl>
                                                    <FormControl error={false} fullWidth={true}>
                                                        <InputLabel htmlFor="currentNatjecanjeGodina">Godina</InputLabel>
                                                        <Select
                                                            native
                                                            value={this.props.d.currentNatjecanjeGodina}
                                                            onChange={this.updateStateWithEvent.bind(this)}
                                                            inputProps={{
                                                                name: 'currentNatjecanjeGodina',
                                                                id: 'currentNatjecanjeGodina',
                                                            }}>
                                                            {this.getLastYearsOptions().map(option => {
                                                                return (<option value={option.value}>{option.text}</option>);
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                    </FormControl>
                                                    <FormControl error={false} fullWidth={true}>
                                                        <InputLabel htmlFor="currentNatjecanjeMjesto">Osvojeno mjesto</InputLabel>
                                                        <Select
                                                            native
                                                            value={this.props.d.currentNatjecanjeMjesto}
                                                            onChange={this.updateStateWithEvent.bind(this)}
                                                            inputProps={{
                                                                name: 'currentNatjecanjeMjesto',
                                                                id: 'currentNatjecanjeMjesto',
                                                            }}>
                                                            {constData.mjestaOptions.map(option => {
                                                                return (<option value={option.value}>{option.text}</option>);
                                                            })}
                                                        </Select>
                                                        <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                    </FormControl>
                                                    <FormControl error={false} fullWidth={true}>
                                                        <TextField
                                                            id="outlined-number"
                                                            label="Predmet"
                                                            fullWidth={true}
                                                            value={this.props.d.currentNatjecanjePredmet}
                                                            onChange={this.updateStateWithEvent.bind(this)}
                                                            name="currentNatjecanjePredmet"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            margin="normal"
                                                            variant="outlined"
                                                        />
                                                        <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                    </FormControl>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleCloseNatjecanja} color="primary">
                                                        Odustani
                                                        </Button>
                                                    <Button onClick={this.handleSaveNatjecanja} color="primary">
                                                        Dodaj
                                                        </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Paper>
                                                {this.props.d.nagrade.length === 0 ? (
                                                    <p>Nemaš dodanih natjecanja. Dodaj ih klikom na +.</p>
                                                ) : (
                                                        this.props.d.natjecanja.map(natjecanje => {
                                                            return (
                                                                <Chip
                                                                    key={natjecanje.key}
                                                                    color="primary"
                                                                    label={`[${natjecanje.mjesto}. mjesto] ${natjecanje.tip} - ${natjecanje.predmet.toUpperCase()} (${natjecanje.godina}.)`}
                                                                    onDelete={this.handleDeleteNatjecanja(natjecanje)}
                                                                />
                                                            );
                                                        })
                                                    )}
                                            </Paper>
                                        </div>
                                    ) : undefined}
                                </div>
                            ) : (
                                    <div className="form-group">
                                        <Switch
                                            checked={this.props.d.checkedNagrade}
                                            onChange={this.updateStateMaterialChecked('checkedNagrade')}
                                            color="primary"
                                            value="checkedNagrade"
                                            label=""
                                        />
                                        <label>Imam nagrade na učilišnoj ili sveučilišnoj razini</label>
                                        {this.props.d.checkedNagrade ? (
                                            <div>
                                                <Fab color="primary" aria-label="add" size="small" onClick={this.handleClickOpenNagrade}>
                                                    <AddIcon />
                                                </Fab>
                                                <p></p>
                                                <Dialog
                                                    open={this.props.d.openNagrade}
                                                    onClose={this.handleCloseNagrade}
                                                    aria-labelledby="form-dialog-title"
                                                >
                                                    <DialogTitle id="form-dialog-title">Dodaj nagradu</DialogTitle>
                                                    <DialogContent>
                                                        <FormControl error={false} fullWidth={true}>
                                                            <InputLabel htmlFor="currentNagradaTip">Vrsta nagrade</InputLabel>
                                                            <Select
                                                                native
                                                                value={this.props.d.currentNagradaTip}
                                                                onChange={this.updateStateWithEvent.bind(this)}
                                                                inputProps={{
                                                                    name: 'currentNagradaTip',
                                                                    id: 'currentNagradaTip',
                                                                }}>
                                                                {constData.nagradeOptions.map(option => {
                                                                    return (<option value={option.value}>{option.text}</option>);
                                                                })}
                                                            </Select>
                                                            <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                        </FormControl>
                                                        <FormControl error={false} fullWidth={true}>
                                                            <InputLabel htmlFor="currentNagradaGodina">Godina</InputLabel>
                                                            <Select
                                                                native
                                                                value={this.props.d.currentNagradaGodina}
                                                                onChange={this.updateStateWithEvent.bind(this)}
                                                                inputProps={{
                                                                    name: 'currentNagradaGodina',
                                                                    id: 'currentNagradaGodina',
                                                                }}>
                                                                {this.getLastYearsOptions().map(option => {
                                                                    return (<option value={option.value}>{option.text}</option>);
                                                                })}
                                                            </Select>
                                                            <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                        </FormControl>
                                                        <FormControl error={false} fullWidth={true}>
                                                            <TextField
                                                                id="outlined-number"
                                                                label="Naziv"
                                                                fullWidth={true}
                                                                value={this.props.d.currentNagradaNaziv}
                                                                onChange={this.updateStateWithEvent.bind(this)}
                                                                name="currentNagradaNaziv"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                margin="normal"
                                                                variant="outlined"
                                                            />
                                                            <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                                        </FormControl>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={this.handleCloseNagrade} color="primary">
                                                            Odustani
                                                        </Button>
                                                        <Button onClick={this.handleSaveNagrade} color="primary">
                                                            Dodaj
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                                <Paper>
                                                    {this.props.d.nagrade.length === 0 ? (
                                                        <p>Nemaš dodanih nagrada. Dodaj ih klikom na +.</p>
                                                    ) : (
                                                            this.props.d.nagrade.map(nagrada => {
                                                                return (
                                                                    <Chip
                                                                        key={nagrada.key}
                                                                        label={`[${nagrada.tip} - ${nagrada.godina}] ${nagrada.naslov}`}
                                                                        onDelete={this.handleDeleteNagrade(nagrada)}
                                                                    />
                                                                );
                                                            })
                                                        )}
                                                </Paper>
                                            </div>
                                        ) : undefined}
                                    </div>
                                )}
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Switch
                                checked={this.props.d.checkedProjekti}
                                onChange={this.updateStateMaterialChecked('checkedProjekti')}
                                color="primary"
                                value="checkedProjekti"
                                label=""
                            />
                            <label>Sudjelovao sam u organizaciji/provođenju projekata</label>
                            {this.props.d.checkedProjekti ? (
                                <div>
                                    <Fab color="primary" aria-label="add" size="small" onClick={this.handleClickOpenProjekti}>
                                        <AddIcon />
                                    </Fab>
                                    <p></p>
                                    <Dialog
                                        open={this.props.d.openProjekti}
                                        onClose={this.handleCloseProjekti}
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title">Dodaj projektnu aktivnosti</DialogTitle>
                                        <DialogContent>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentProjectTip">Tip aktivnosti</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.d.currentProjectTip}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentProjectTip',
                                                        id: 'currentProjectTip',
                                                    }}>
                                                    {constData.projektiOptions.map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <InputLabel htmlFor="currentProjectGodina">Godina</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.d.currentProjectGodina}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    inputProps={{
                                                        name: 'currentProjectGodina',
                                                        id: 'currentProjectGodina',
                                                    }}>
                                                    {this.getLastYearsOptions().map(option => {
                                                        return (<option value={option.value}>{option.text}</option>);
                                                    })}
                                                </Select>
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Donator"
                                                    fullWidth={true}
                                                    value={this.props.d.currentProjectDonator}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentProjectDonator"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                            <FormControl error={false} fullWidth={true}>
                                                <TextField
                                                    id="outlined-number"
                                                    label="Naziv"
                                                    fullWidth={true}
                                                    value={this.props.d.currentProjectNaziv}
                                                    onChange={this.updateStateWithEvent.bind(this)}
                                                    name="currentProjectNaziv"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FormHelperText>{!false ? 'Odabir studija je obavezan.' : ''}</FormHelperText>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleCloseProjekti} color="primary">
                                                Odustani
                                            </Button>
                                            <Button onClick={this.handleSaveProjekti} color="primary">
                                                Dodaj
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Paper>
                                        {this.props.d.projekti.length === 0 ? (
                                            <p>Nemaš dodanih projekata. Dodaj ih klikom na +.</p>
                                        ) : (
                                                this.props.d.projekti.map(projekt => {
                                                    return (
                                                        <Chip
                                                            key={projekt.key}
                                                            label={`[${projekt.tip} - ${projekt.godina}] ${projekt.naziv} (${projekt.donator})`}
                                                            onDelete={this.handleDeleteProjekti(projekt)}
                                                        />
                                                    );
                                                })
                                            )}

                                    </Paper>
                                </div>
                            ) : undefined}
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            <Button variant="contained" color="primary" fullWidth={true} onClick={this.saveChanges}><SaveIcon /> Spremi</Button>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="xl" style={{ backgroundColor: "#fff", border: "solid 1px", borderColor: "#cccccc", borderRadius: "5px" }}>
                    <Typography className="mb-1 py-3"><b>UKUPAN BROJ BODOVA: {this.izracunajSumuBodova().toFixed(2)}</b></Typography>

                    {
                        this.isValidUkupniPrihod() && this.isValidBrojClanova() ? (
                            <HighchartsReact highcharts={Highcharts} options={this.getChartOptions()} />
                        ) : undefined
                    }
                </Container>
            </div>
        )
    }

    isPrijavaValid() {
        var validEcts = true;
        if (!this.isMatura()) {
            validEcts = this.props.wizard.ects >= 50;
        }

        return this.props.wizard.prosjek > 3 &&
            this.props.wizard.prosjek <= 5 &&
            validEcts;
    }

    render() {

        return (
            <Container maxWidth="xl">
                {this.renderRedirect()}
                <Typography variant="h4" paragraph={true}>
                    [IZRAČUN BODOVA] Kriteriji za dodjelu stipendija studentima koji studiraju zanimanja od posebnog interesa za Grad Lepoglavu
                </Typography>

                <b>* Dio podataka si unio kroz čarobnjak. Polja označena sa * su obavezna kako bi sustav izvršio potrebne izračune. Ukoliko ne postoje dodatne informacije koje želiš unijeti u ovaj obrazac, klikni izračunaj za prikaz bodova.</b>

                {this.props.wizard.prosjek <= 3 ? (
                    <div className="alert alert-danger custom-alert-style" role="alert">
                        Za prijavu na ovaj kriterij moraš imati prosjek ocjena iznad 3.
                    </div>
                ) : undefined}

                {!this.isMatura() && this.props.wizard.ects < 50 ? (
                    <div className="alert alert-danger custom-alert-style" role="alert">
                        Za prijavu na ovaj kriterij moraš imati minimalno 50 ECTS bodova.
                    </div>
                ) : undefined}

                {this.isPrijavaValid() ? (
                    this.showIzracunContent()
                ) : undefined}
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        wizard: state.wizard,
        d: state.d
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveIzracunProperty: (name, value) => dispatch({ type: SAVE_IZRACUN_PROPERTY, payload: { name: name, value: value } }),
    dodajNatjecanje: (natjecanje) => dispatch({ type: ADD_NATJECANJE, payload: natjecanje }),
    deleteNatjecanje: (natjecanje) => dispatch({ type: DELETE_NATJECANJE, payload: natjecanje }),
    dodajNagradu: (nagrada) => dispatch({ type: ADD_NAGRADA, payload: nagrada }),
    deleteNagrada: (nagrada) => dispatch({ type: DELETE_NAGRADA, payload: nagrada }),
    dodajProjekt: (projekt) => dispatch({ type: ADD_PROJEKT, payload: projekt }),
    deleteProjekt: (projekt) => dispatch({ type: DELETE_PROJEKT, payload: projekt }),
    clearInputModals: () => dispatch({ type: CLEAR_INPUT_MODALS }),
})


export default connect(mapStateToProps, mapDispatchToProps)(IzracunDeficitarni);