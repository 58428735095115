import React from 'react';

import './style.css';
import { destroyObjectProperties } from 'highcharts';


export default function Quote(props) {
    return (
        <div class="row quote-container">
            <div class="col-sm-12 col-lg-12">
                <div data-ride="carousel" id="quote-carousel">
                    <div class="carousel-inner">
                        <div class="item">
                            <blockquote>
                                <div class="row">
                                    <div class="col-sm-3 col-md-3 col-lg-2 text-center">
                                        <img class="img-circle" src={ props.authorImage } style={{ width: "100px", height: "100px" }} />
                                    </div>
                                    <div class="col-sm-9 col-md-9 col-lg-10">
                                        <p class="quote-text">{ props.text }</p>
                                        <small class="quote-author">{ props.author }</small>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}