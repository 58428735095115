import React, { Component } from 'react';

import { Container, TextField, Grid, Button, Select, FormControl, MenuItem, InputLabel, Typography, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';


import axios from 'axios';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formResponseStatus: "",
            formResponseMessage: "",
            formSending: false,
            name: "",
            email: "",
            subject: "",
            message: "",
            recaptchaChecked: false
        };
    }

    componentDidMount() {
        window.grecaptcha.render("recaptcha", {
            sitekey: "6Le2WMsUAAAAAEySgnP_9Ob8hn5oI5-L62hp_Kzn",
            callback: this.recaptchaCallback,
            "expired-callback": this.recaptchaCallbackExpired,
            "error-callback": this.recaptchaCallbackNetworkError
        });
    }

    recaptchaCallback = () => {
        this.setState({ ...this.state, recaptchaChecked: true });
    }

    recaptchaCallbackExpired = () => {
        this.setState({ ...this.state, recaptchaChecked: false });
    }

    recaptchaCallbackNetworkError = () => {
        this.setState({ ...this.state, recaptchaChecked: false });
    }

    updateStateWithEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    isValideName() {
        return this.state.name !== "";
    }
    isValideSubject() {
        return this.state.subject !== "";
    }
    isValideMessage() {
        return this.state.message !== "" && this.state.message.length > 25;
    }
    isValideEmail() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.state.email).toLowerCase());
    }

    isFormValid() {
        return this.isValideName() && this.isValideSubject() && this.isValideMessage() && this.isValideEmail() && this.state.recaptchaChecked;
    }

    submitForm() {
        if (!this.isFormValid()) {
            return;
        }
        this.setState({ ...this.state, formSending: true });

        axios.post('/sendmail.php', {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
            "g-recaptcha-response": window.grecaptcha.getResponse()
        })
        .then(response => {
            this.setState({ ...this.state, formResponseStatus: response.data.status, formResponseMessage: response.data.message, formSending: false });
        })
    }

    render() {
        return (
            <Container maxWidth="lg">
                <Typography variant="h4" className="mb-5">Kontakt</Typography>

                <Grid container direction="row" justify="center" spacing={10}>
                    <Grid item xs={12} lg={6}>
                        {
                            this.state.formResponseStatus === "OK" &&
                            <div className="alert alert-success">{this.state.formResponseMessage}</div>
                        }

                        {
                            this.state.formResponseStatus !== "OK" &&
                            <Grid container direction="column" justify="center" spacing={4}>
                                <Grid item>
                                    <FormControl fullWidth={true}>
                                        <TextField
                                            error={!this.isValideName()}
                                            id="contact-ime"
                                            name="name"
                                            label="Ime"
                                            required={true}
                                            value={this.state.name}
                                            onChange={this.updateStateWithEvent.bind(this)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth={true}>
                                        <TextField
                                            error={!this.isValideEmail()}
                                            id="contact-email"
                                            name="email"
                                            label="E-mail adresa"
                                            required={true}
                                            value={this.state.email}
                                            onChange={this.updateStateWithEvent.bind(this)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth={true} error={!this.isValideSubject()} required={true}>
                                        <InputLabel id="contact-subject-label">Predmet</InputLabel>
                                        <Select
                                            labelId="contact-subject-label"
                                            id="contact-subject"
                                            name="subject"
                                            value={this.state.subject}
                                            onChange={this.updateStateWithEvent.bind(this)}>
                                            <MenuItem value=""></MenuItem>
                                            <MenuItem value="Pitanje">Pitanje</MenuItem>
                                            <MenuItem value="Komentar">Komentar</MenuItem>
                                            <MenuItem value="Problem">Problem</MenuItem>
                                            <MenuItem value="Ostalo">Ostalo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth={true} >
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Poruka"
                                            name="message"
                                            multiline
                                            error={!this.isValideMessage()}
                                            value={this.state.message}
                                            onChange={this.updateStateWithEvent.bind(this)}
                                            rows="10"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                { this.state.formResponseStatus === "ERROR" && 
                                <Grid item>
                                    <div className="alert alert-danger">{this.state.formResponseMessage}</div>
                                </Grid>
                                }
                                <Grid item>
                                    <div id="recaptcha"></div>
                                </Grid>
                                <Grid item>
                                    <Button fullWidth={true} variant="contained" color="primary" disabled={!this.isFormValid() || this.state.formSending} onClick={this.submitForm.bind(this)}>
                                        <MailIcon />Pošalji
                                    </Button>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container direction="column" justify="center" spacing={5}>
                            <Grid item xs={12} lg={12}>
                                <Typography variant="h6" paragraph={true}>Grad Lepoglava</Typography>
                                <ListItemText primary="ODSJEK ZA OPĆE POSLOVE I DRUŠTVENE DJELATNOSTI" />
                                <ListItemText primary="Antuna Mihanovića 12" />
                                <ListItemText primary="42250 Lepoglava" />
                                <ListItem>
                                    <ListItemIcon>
                                        <PhoneIphoneIcon />
                                    </ListItemIcon>
                                    <a href="tel:+38542770421">
                                        <ListItemText primary="+385 42 770 421" />
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <MailOutlineIcon />
                                    </ListItemIcon>
                                    <a href="mailto:josipa.putanec@lepoglava.hr">
                                        <ListItemText primary="josipa.putanec@lepoglava.hr" />
                                    </a>
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Typography variant="h6" paragraph={true}>Savjet mladih Grada Lepoglave</Typography>

                                <ListItemText primary="Antuna Mihanovića 12" />
                                <ListItemText primary="42250 Lepoglava" />
                                <ListItem>
                                    <ListItemIcon>
                                        <MailOutlineIcon />
                                    </ListItemIcon>
                                    <a href="mailto:savjetmladih@lepoglava.hr">
                                        <ListItemText primary="savjetmladih@lepoglava.hr" />
                                    </a>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default Contact;