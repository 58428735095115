import { SAVE_IZRACUN_PROPERTY, ADD_PROJEKT, DELETE_PROJEKT, ADD_NAGRADA, DELETE_NAGRADA, ADD_RAZMJENA, DELETE_RAZMJENA, CLEAR_INPUT_MODALS } from '../actions/iActions';

import loadState from '../utils/storageManager';

const initialState = {
    objavljenRad: false,
    checkedProjekti: false,
    openProjekti: false,
    currentProjectTip: "",
    currentProjectGodina: "",
    currentProjectDonator: "",
    currentProjectNaziv: "",
    checkedNagrade: false,
    openNagrade: false,
    currentNagradaTip: "",
    currentNagradaGodina: "",
    currentNagradaNaziv: "",
    checkedRazmjene: false,
    openRazmjene: false,
    currentRazmjenaGodina: "",
    currentRazmjenaTip: "",
    currentRazmjenaMjesto: "",
    currentRazmjenaNaziv: "",
    razmjene: [], // [Razmjena u trajanju od 2 mjeseca] - Erasmus+ semestralna razmjena - (Krakow, 2017.)
    nagrade: [], // [Rektorova nagrada - 2018.] Proteomski pristup pretilosti upasa
    projekti: [], // [Voditelj projektnog tima - 2017.] - Youth lead (Agencija za mobilnost i programe EU)
    sumaBodova: 0
}

const testState = {
    objavljenRad: false,
    checkedProjekti: true,
    openProjekti: false,
    currentProjectTip: "",
    currentProjectGodina: "",
    currentProjectDonator: "",
    currentProjectNaziv: "",
    checkedNagrade: true,
    openNagrade: false,
    currentNagradaTip: "",
    currentNagradaGodina: "",
    currentNagradaNaziv: "",
    checkedRazmjene: true,
    openRazmjene: false,
    currentRazmjenaGodina: "",
    currentRazmjenaTip: "",
    currentRazmjenaMjesto: "",
    currentRazmjenaNaziv: "",
    razmjene: [{
        key: 0,
        tip: "Razmjena u trajanju manje od 2 mjeseca",
        godina: 2019,
        mjesto: "Zagreb",
        naziv: "propoplis",
    }], // [Razmjena u trajanju od 2 mjeseca] - Erasmus+ semestralna razmjena - (Krakow, 2017.)
    nagrade: [{
        key: 0,
        tip: "Dekanova nagrada",
        godina: 2019,
        naslov: "Some shit"
    }], // [Rektorova nagrada - 2018.] Proteomski pristup pretilosti upasa
    projekti: [{
        key: 0,
        tip: "Voditelj projektnog tima",
        godina: 2019,
        naziv: "Okoloko",
        donator: "Ministarstvo regionalnog razvoja",
    }], // [Voditelj projektnog tima - 2017.] - Youth lead (Agencija za mobilnost i programe EU)
    sumaBodova: 0
}

export default function iReducers(state = initialState, action) {
    switch (action.type) {
        case SAVE_IZRACUN_PROPERTY:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case ADD_NAGRADA:
            return {
                ...state,
                nagrade: [...state.nagrade, action.payload]
            }
        case DELETE_NAGRADA:
            const nagrade = [...state.nagrade];
            const nagradaToDelete = nagrade.indexOf(action.payload);
            nagrade.splice(nagradaToDelete, 1);
            return {
                ...state,
                nagrade: nagrade
            }
        case ADD_PROJEKT:
            return {
                ...state,
                projekti: [...state.projekti, action.payload]
            }
        case DELETE_PROJEKT:
            const projekti = [...state.projekti];
            const projektToDelete = projekti.indexOf(action.payload);
            projekti.splice(projektToDelete, 1);
            return {
                ...state,
                projekti: projekti
            }
        case ADD_RAZMJENA:
            return {
                ...state,
                razmjene: [...state.razmjene, action.payload]
            }
        case DELETE_RAZMJENA:
            const razmjene = [...state.projerazmjenekti];
            const razmjenaToDelete = razmjene.indexOf(action.payload);
            razmjene.splice(razmjenaToDelete, 1);
            return {
                ...state,
                razmjene: razmjene
            }
        case CLEAR_INPUT_MODALS:
            return {
                ...state,
                openNagrade: false,
                currentNagradaTip: "",
                currentNagradaGodina: "",
                currentNagradaNaziv: "",
                openProjekti: false,
                currentProjectTip: "",
                currentProjectGodina: "",
                currentProjectDonator: "",
                currentProjectNaziv: "",
                openRazmjene: false,
                currentRazmjenaGodina: "",
                currentRazmjenaTip: "",
                currentRazmjenaMjesto: "",
                currentRazmjenaNaziv: "",
            }
        default:
            let storedState = loadState();
            return storedState.hasOwnProperty("i") ? storedState.i : state;
    }
}